import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { PlageTemporelle } from 'src/app/constants/plage.temporelle';
import { ControllerService } from 'src/app/services/controller.service';
import { DateService } from 'src/app/services/date.service';
import { FilterService } from 'src/app/services/filter.service';
import { IndicateurService } from 'src/app/services/indicateur.service';
import { CHOIX } from '../../../constants/list.const';
import { DataService } from '../../../services/data.service';

import {MobileStateService} from 'src/app/services/mobile-state.service';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-header-calendar',
  templateUrl: './header-calendar.component.html',
  styleUrls: ['./header-calendar.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class HeaderCalendarComponent implements OnInit {
  currentDay = new Date();
  days = this.currentDay.getUTCDate();
  month = this.currentDay.getUTCMonth();
  year = this.currentDay.getUTCFullYear();
  datePicker = new FormControl(moment([this.year, this.month, this.days]));
  @Output()
  dateChange = new EventEmitter<boolean>();
  deviceMobile:boolean = false;
  constructor(private adapter: DateAdapter<any>,
              private dataService: DataService,
              private filterService: FilterService,
              private indicateurService: IndicateurService,
              private dateService: DateService,
              private controllerService: ControllerService,
              private mobileStateService: MobileStateService
  ) {
    this.adapter.setLocale('fr'); // date version française: DD:MM:YYYY;
  }
  ngOnInit() {
    this.datePicker.patchValue(moment(this.dateService.date));
    this.mobileStateService.subscribeToLayoutChanges().subscribe(observerResponse => {

      this.deviceMobile = this.mobileStateService.isMobile();


    });
  }

  onChooseDate(event) {
    this.dateChange.emit(false);
    const m = moment(event.value);
    if (m.isValid()) {
      const date = new Date(event.value);
      this.dataService.setDate(date);
      this.indicateurService.loadIndice(date);
      // when changing date, if the choice is aufildeau, changint it to 'Tous';
      if (this.controllerService.userChoice.group === CHOIX.AuFilDeau) {
        this.controllerService.userChoice.group = CHOIX.TOUS;
      }
    }
  }

  public onSuiviDateChange(): void {
    const date = new Date();
    this.datePicker.patchValue(moment(date));
    this.dataService.setDate(date);
    this.indicateurService.loadIndice(date);
  }
}
