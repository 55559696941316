import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Event } from '../models/event.model';
import { ChoiceKeeper } from '../models/choice.keeper.model';
import { Incomp } from '../models/incomp.model';
@Injectable({
  providedIn: 'root',
})
export class ControllerService {
  idEvtClickedInList: number;
  private eventsMap: Map<number, Event> = new Map<number, Event>();
  private incompMap: Map<number, Incomp> = new Map<number, Incomp>();
  evtSubjectSrcMap: Subject<Event> = new Subject<Event>();
  evtSubjectSrcList: Subject<Event> = new Subject<Event>();
  eventListSubject: Subject<Event[]> = new Subject<Event[]>();
  evtDetailCloseSubject: Subject<boolean> = new Subject<boolean>();
  centerMapSubject: Subject<L.LatLng> = new Subject<L.LatLng>();
  incompSubjectSrcMap: Subject<Incomp> = new Subject<Incomp>();
  userChoice: ChoiceKeeper = new ChoiceKeeper('Tous');
  filterMobileSubject: Subject<boolean> = new Subject<boolean>();

  disableAnimation = true;
  showLegendTable = false;
  showLegendIcon = false;
  showFiltre = false;

  emitPosition(latlng: L.LatLng) {
    this.centerMapSubject.next(latlng);
  }

  setMap(id: number, event: Event): void {
    this.eventsMap.set(id, event);
  }

  setIncompMap(id: number, incomp: Incomp): void {
    this.incompMap.set(id, incomp);
  }

  setIncompClicked(id: number): void {
    this.emitIncompOf(this.incompMap.get(id));
  }
  setEventClicked(id: number): void {
    this.emitEventOf(this.eventsMap.get(id));
  }

  emitEventOf(evt: Event) {
    this.evtSubjectSrcMap.next(evt);
  }

  emitIncompOf(incomp: Incomp) {
    this.incompSubjectSrcMap.next(incomp);
  }

  emitEvtList(evtList: Event[]) {
    this.eventListSubject.next(evtList);
  }

  emitEvtSrcList(evt: Event) {
    this.evtSubjectSrcList.next(evt);
  }

  emitEvtDetailState(isOpen: boolean) {
    this.evtDetailCloseSubject.next(isOpen);
  }

  emitFilterMobileState(isOpen: boolean) {
    this.filterMobileSubject.next(isOpen);
  }
  clearMap(): void {
    this.eventsMap.clear();
    this.incompMap.clear();
  }


}
