import {Legend} from '../models/legend.model';

const COLORS = {
  E_FONCE: '#00004d',  // évt
  E_MOYEN: '#0000FF',  // évt
  E_CLAIRE: '#9999FF',  // évt
  E_CLOSE_LANE: '#bd0213', // Fermeture des routes causée par un événement (rouge)
  E_HALF_CLOSE_LANE : '#f94705', // Fermeture partielle des routes causée par un événement (orange)
  E_MANIF: '#a547fd', // violet
  OCC_MOYEN: '#654321', // occupations
  M_COLOR: '#37B9C3',  // mission/opération
  F_BIENS: '#851de0',   // fait
  F_PERSO: '#df42d1',   // fait
  F_ENV: '#009900',   // fait
  F_TRANQ: '#000099',   // fait
  F_CIRC: '#FFFF00',   // fait
  F_STAT: '#FFA500',   // fait
  T_Travo: 'yellow', // yellow
  T_Travo_stroke: '#cccc02', // dark yellow
  T_Arretes: 'yellow', // yellow
  T_Picto: '#654321',  // dark brown
  Tunnel: 'red',
  F_ACCIDENT: '#df42d1',
  F_JAM: '#c44a06',
  F_CONSTRUCTION: '#fcdc30',
  F_WEATHERHAZARD: '#ff0000',
  F_MISC: '#ffd88a',
  F_ROAD_CLOSED: '#ffd700'
};

const COLORS_FONTS = {
  E_FONCE: 'white',   // évt
  E_MOYEN: 'white',   // évt
  E_CLAIRE: 'white',   // évt
  M_COLOR: 'white',   // mission/opération
  F_BIENS: 'white',   // fait
  F_PERSO: 'white',   // fait
  F_ENV: 'white',   // fait
  F_TRANQ: 'white',   // fait
  F_CIRC: 'black',   // fait
  F_STAT: 'white',   // fait
  T_Travo: 'white',   // brown
  T_Arretes: '#333',   // yellow
  T_Picto: 'white',   // dark brown
  Tunnel: 'white',
  F_ACCIDENT: 'white',
  F_JAM: 'white',
  F_CONSTRUCTION: 'black',
  F_WEATHERHAZARD: 'white',
  F_MISC: 'black',
  F_ROAD_CLOSED: 'black'
};

// cluster radius;
export const CLUSTER_RADIUS = 20;

export class CategoryUtil {// ne change pas l'ordre; les groupements 'Opérations, Fermetures,Evenements,' sont insérés selon la clé de map;
  INCOMP_STYLE = {weight: 1, color: 'red', opacity: 0.6, fillColor: 'red', fillOpacity: 0.2};

  categoryMap = new Map([
    ['Festif/Culturel', {
      checked: true,
      typeFlag: 'E',
      radius: 50.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Festif-Culturel-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Festif-Culturel-Normal.svg',
      markerHoverUrl: 'assets/markers/Festif-Culturel-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Festif-Culturel-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Festif-Culturel-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Festif-Culturel-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Festif-Culturel-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Festif-Culturel-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_rassemblement.svg',
      font_color: COLORS_FONTS.E_CLAIRE,
      color: COLORS.E_CLAIRE,
      style_emprise: {color: COLORS.E_CLAIRE, opacity: 1.0, weight: 2, fillColor: COLORS.E_CLAIRE, fillOpacity: 0.70},
      style_impact_halo: {
        color: COLORS.E_CLAIRE,
        opacity: 0.70,
        weight: 2,
        fillColor: COLORS.E_CLAIRE,
        fillOpacity: 0.3
      },
      def: ['Festival, spectacle, exposition, etc. pouvant entraîner l\'afflux de personnes sur le domaine public', 'Fête, soirée, gala, carnaval, parade, défilé, fête de quartier, campagne de sensibilisation.', 'Fête foraine, manège, grande roue.']
    }],
    ['Marché', {
      checked: true,
      typeFlag: 'E',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Marche-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Marche-Normal.svg',
      markerHoverUrl: 'assets/markers/Marche-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Marche-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Marche-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Marche-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Marche-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Marche-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_marche.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.E_MOYEN,
      style_emprise: {color: COLORS.E_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.E_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.E_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.E_MOYEN, fillOpacity: 0.3},
      def: ['Marché, brocante, vide-grenier, foire.']
    }],
    ['Sportif', {
      checked: true,
      typeFlag: 'E',
      radius: 50.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Sportif-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Sportif-Normal.svg',
      markerHoverUrl: 'assets/markers/Sportif-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Sportif-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Sportif-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Sportif-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Sportif-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Sportif-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_sportif.svg',
      font_color: COLORS_FONTS.E_CLAIRE,
      color: COLORS.E_CLAIRE,
      style_emprise: {color: COLORS.E_CLAIRE, opacity: 1.0, weight: 2, fillColor: COLORS.E_CLAIRE, fillOpacity: 0.70},
      style_impact_halo: {
        color: COLORS.E_CLAIRE,
        opacity: 0.70,
        weight: 2,
        fillColor: COLORS.E_CLAIRE,
        fillOpacity: 0.3
      },
      def: ['Manifestation sportive se déroulant sur le domaine public (course à pied, course cycliste, etc.) ou de type motorisé (défilé de voiture, rallye).', 'Manifestation sportive se déroulant hors du domaine public mais pouvant potentiellement générer l\'afflux de personnes aux alentours du lieu (match de football, compétition d\'athlétisme).']
    }],
    ['Tournage', {
      checked: true,
      typeFlag: 'E',
      radius: 20.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Tournage-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Tournage-Normal.svg',
      markerHoverUrl: 'assets/markers/Tournage-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Tournage-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Tournage-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Tournage-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Tournage-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Tournage-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_tournage.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.E_MOYEN,
      style_emprise: {color: COLORS.E_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.E_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.E_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.E_MOYEN, fillOpacity: 0.3},
      def: ['Film, clip, documentaire, etc. Plateau d\'émission de télé. Prises de vues, photos.']
    }],
    ['Cultuel', {
      checked: true,
      typeFlag: 'E',
      radius: 30.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Cultuel-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Cultuel-Normal.svg',
      markerHoverUrl: 'assets/markers/Cultuel-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Cultuel-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Cultuel-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Cultuel-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Cultuel-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Cultuel-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_cultuel.svg',
      font_color: COLORS_FONTS.E_FONCE,
      color: COLORS.E_FONCE,
      style_emprise: {color: COLORS.E_FONCE, opacity: 1.0, weight: 2, fillColor: COLORS.E_FONCE, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.E_FONCE, opacity: 0.70, weight: 2, fillColor: COLORS.E_FONCE, fillOpacity: 0.3},
      def: ['Activité religieuse ou à caractère cultuel : célébration de fête religieuse sur le domaine public ou dans un lieu de culte, procession, pèlerinage, etc.']
    }],
    ['Divers', {
      checked: true,
      typeFlag: 'E',
      radius: 10.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Divers-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Divers-Normal.svg',
      markerHoverUrl: 'assets/markers/Divers-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Divers-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Divers-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Divers-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Divers-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Divers-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_divers.svg',
      font_color: COLORS_FONTS.E_CLAIRE,
      color: COLORS.E_CLAIRE,
      style_emprise: {color: COLORS.E_CLAIRE, opacity: 1.0, weight: 2, fillColor: COLORS.E_CLAIRE, fillOpacity: 0.70},
      style_impact_halo: {
        color: COLORS.E_CLAIRE,
        opacity: 0.70,
        weight: 2,
        fillColor: COLORS.E_CLAIRE,
        fillOpacity: 0.3
      },
      def: ['Événement dont la catégorie ne correspond à aucune de celles définies par ailleurs.']
    }],

    ['benne', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Benne-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Benne-Normal.svg',
      markerHoverUrl: 'assets/markers/Benne-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Benne-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Benne-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Benne-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Benne-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Benne-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_benne.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Benne.']
    }],
    ['échafaudage', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Echafaudage-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Echafaudage-Normal.svg',
      markerHoverUrl: 'assets/markers/Echafaudage-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Echafaudage-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Echafaudage-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Echafaudage-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Echafaudage-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Echafaudage-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_echafaudage.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Echafaudage.']
    }],
    ['container', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Container-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Container-Normal.svg',
      markerHoverUrl: 'assets/markers/Container-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Container-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Container-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Container-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Container-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Container-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_container.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Container, Algeco.']
    }],
    ['palissade', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Palissade-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Palissade-Normal.svg',
      markerHoverUrl: 'assets/markers/Palissade-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Palissade-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Palissade-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Palissade-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Palissade-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Palissade-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_palissade.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Palissade, Barriere.']
    }],
    ['piétonisation', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Pietonisation-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Pietonisation-Normal.svg',
      markerHoverUrl: 'assets/markers/Pietonisation-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Pietonisation-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Pietonisation-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Pietonisation-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Pietonisation-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Pietonisation-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_pietonisation.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Pietonisation.']
    }],
    ['barnum', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Barnum-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Barnum-Normal.svg',
      markerHoverUrl: 'assets/markers/Barnum-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Barnum-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Barnum-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Barnum-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Barnum-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Barnum-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_barnum.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Barnum, Tente.']
    }],
    ['cantine', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Cantine-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Cantine-Normal.svg',
      markerHoverUrl: 'assets/markers/Cantine-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Cantine-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Cantine-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Cantine-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Cantine-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Cantine-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_cantine.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Cantine.']
    }],
    ['nettoyage', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Nettoyage-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Nettoyage-Normal.svg',
      markerHoverUrl: 'assets/markers/Nettoyage-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Nettoyage-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Nettoyage-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Nettoyage-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Nettoyage-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Nettoyage-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_nettoyage.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Nettoyage.']
    }],
    ['pavoisement', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Pavoisement-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Pavoisement-Normal.svg',
      markerHoverUrl: 'assets/markers/Pavoisement-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Pavoisement-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Pavoisement-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Pavoisement-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Pavoisement-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Pavoisement-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_pavoisement.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Pavoisement.']
    }],
    ['divers', {
      checked: true,
      typeFlag: 'OCC',
      radius: 25.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Divers-Occ-Normal.svg',
      provMarkerUrl: 'assets/markers/provisional/Divers-Occ-Normal.svg',
      markerHoverUrl: 'assets/markers/Divers-Occ-Hover.svg',
      provMarkerHoverUrl: 'assets/markers/provisional/Divers-Occ-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Divers-Occ-Risque-Hover.svg',
      provMarkerHoverRisqueUrl: 'assets/markers/provisional/Divers-Occ-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Divers-Occ-Risque.svg',
      provRiskMarkerUrl: 'assets/markers/provisional/Divers-Occ-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_divers_occ.svg',
      font_color: COLORS_FONTS.E_MOYEN,
      color: COLORS.OCC_MOYEN,
      style_emprise: {color: COLORS.OCC_MOYEN, opacity: 1.0, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.OCC_MOYEN, opacity: 0.70, weight: 2, fillColor: COLORS.OCC_MOYEN, fillOpacity: 0.3},
      def: ['Divers.']
    }],
    ['Travaux (sur voirie)', {
      checked: true,
      typeFlag: 'T',
      radius: 0.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Travaux-Arretes.svg',
      markerHoverUrl: 'assets/markers/Travaux-Arretes-Hover.svg',
      markerHoverRisqueUrl: 'assets/markers/Travaux-Arretes-Risque-Hover.svg',
      riskMarkerUrl: 'assets/markers/Travaux-Arretes-Risque.svg',
      impactMarkerUrl: 'assets/impacts/impact_Violet.svg',
      iconUrl: 'assets/icons/ic_travaux_arrete.svg',
      font_color: COLORS_FONTS.T_Arretes,
      color: COLORS.T_Arretes,
      style_emprise: {color: COLORS.T_Travo_stroke, opacity: 0.8, weight: 2, fillColor: COLORS.T_Travo, fillOpacity: 0.70},
      style_impact_halo: {
        color: COLORS.T_Arretes,
        opacity: 0.8,
        weight: 2,
        fillColor: COLORS.T_Arretes,
        fillOpacity: 0.3
      },
      def: ['Travaux sur le domaine public : voirie, réseau, distribution, stationnement, mobilier urbain, etc.']
    }],

    ['Fermeture des tunnels', {
      checked: true,
      typeFlag: 'U',
      radius: 30.0,
      iconSize: 1.5,
      markerUrl: 'assets/markers/Tunnel-ferme.svg',
      markerHoverUrl: 'assets/markers/Tunnel-ferme.svg',
      markerHoverRisqueUrl: 'assets/markers/Tunnel-ferme.svg',
      riskMarkerUrl: 'assets/markers/Tunnel-ferme.svg',
      impactMarkerUrl: 'assets/impacts/impact_CuriousBlue.svg',
      iconUrl: 'assets/icons/ic_travaux.svg',
      font_color: COLORS_FONTS.Tunnel,
      color: COLORS.Tunnel,
      style_emprise: {color: COLORS.Tunnel, opacity: 1.0, weight: 1, fillColor: COLORS.Tunnel, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.Tunnel, opacity: 1.0, weight: 1, fillColor: COLORS.Tunnel, fillOpacity: 0.70},
      def: ['Fermeture prévue : maintenance programmée, travaux, etc.']
    }],
    // A garder si par la suite on reçoit des données de fermetures inopinées des tunnels
    // ['Fermeture innopinée des voies (tunnel)', {
    //   checked: true,
    //   typeFlag: 'U',
    //   radius: 30.0,
    //   iconSize: 1.5,
    //   markerUrl: 'assets/markers/Tunnel-ferme.svg',
    //   markerHoverUrl: 'assets/markers/Tunnel-ferme.svg',
    //   markerHoverRisqueUrl: 'assets/markers/Tunnel-ferme.svg',
    //   riskMarkerUrl: 'assets/markers/Tunnel-ferme.svg',
    //   impactMarkerUrl: 'assets/impacts/impact_CuriousBlue.svg',
    //   iconUrl: 'assets/icons/ic_travaux.svg',
    //   font_color: COLORS_FONTS.Tunnel,
    //   color: COLORS.Tunnel,
    //   style_emprise: {color: COLORS.Tunnel, opacity: 1.0, weight: 1, fillColor: COLORS.Tunnel, fillOpacity: 0.70},
    //   style_impact_halo: {color: COLORS.Tunnel, opacity: 1.0, weight: 1, fillColor: COLORS.Tunnel, fillOpacity: 0.70},
    //   def: ['Fermeture inopinée : maintenance non programmée, incident, etc.']
    // }],
    ['Fermeture partielle', {
      checked: true,
      typeFlag: 'V',
      markerUrl: 'assets/markers/traffic/Traffic-level-1.svg',
      def: ['Fermeture partielle des voies (travaux, manifestations, ...)']
    }],
    ['Fermeture complète', {
      checked: true,
      typeFlag: 'V',
      markerUrl: 'assets/markers/traffic/Traffic-level-3.svg',
      def: ['Fermeture complète des voies (travaux, manifestations, ...)']
    }],
  ]);

  categoryMapping = new Map([
    ['E_CULTUEL', {category: 'Cultuel'}],
    ['E_MARCHE', {category: 'Marché'}],
    ['E_TOURNAGE', {category: 'Tournage'}],
    ['E_FESTIF', {category: 'Festif/Culturel'}],
    ['E_SPORTIF', {category: 'Sportif'}],
    ['E_DIVERS', {category: 'Divers'}],
    ['E_TRAVAUX_TEMP', {category: 'Travaux (sur voirie)'}],

    ['OCC_BARNUM', {category: 'barnum'}],
    ['OCC_BENNE', {category: 'benne'}],
    ['OCC_CANTINE', {category: 'cantine'}],
    ['OCC_CONTEN', {category: 'container'}],
    ['OCC_DIVERS', {category: 'divers'}],
    ['OCC_ECHAF', {category: 'échafaudage'}],
    ['OCC_NETTOY', {category: 'nettoyage'}],
    ['OCC_PALISS', {category: 'palissade'}],
    ['OCC_PAVOIS', {category: 'pavoisement'}],
    ['OCC_PIET', {category: 'piétonisation'}],

    ['U_FERM_PREVUE', {category: 'Fermeture des tunnels'}],
    //['U_FERM_INOPINEE', {category: 'Fermeture inopinée'}],
    ['U_FERM_PART_VOIES', {category: 'Fermeture partielle'}],
    ['U_FERM_COMPL_VOIES', {category: 'Fermeture complète'}],
    /* lampe, armoires électriques, etc */
    ['__KO__',       { category: 'Travaux (sur voirie)'}],

  ]);

  natureMapping = new Map([
    ['NAT_E_GRNDEVT', {nature: 'Grand événement'}],
    ['NAT_E_TOURNAG', {nature: 'Reportage/Emission/Film/Photo'}],
    ['NAT_E_CONFESS', {nature: 'Confessionnel/Cultuel'}],
    ['NAT_E_POLITIQ', {nature: 'Politique/Meeting/etc'}],
    ['NAT_E_MANIFES', {nature: 'Manifestation/Revendicatif'}],
    ['NAT_E_FESTIF', {nature: 'Fête/Concert/Bal/Parade'}],
    ['NAT_E_CULTURE', {nature: 'Expo/Spectacle/Culture'}],
    ['NAT_E_SPORTIF', {nature: 'Sport Outdoor'}],
    ['NAT_E_SPORHDP', {nature: 'Sport Indoor/En stade/Nautique'}],
    ['NAT_E_MOTORIS', {nature: 'Sportif/Motorisé'}],
    ['NAT_E_MARCHE', {nature: 'Marché/Foire'}],
    ['NAT_E_OCCASIO', {nature: 'Fête/Repas/Célébration/Sensibilisation'}],
    ['NAT_E_PYROTEC', {nature: 'Spectacle pyrotechnique'}],
    ['NAT_E_SURVOL', {nature: 'Survol aérien'}],
    ['NAT_E_TRAVAUX', {nature: 'Travaux'}],
    ['NAT_E_ORDMIS', {nature: 'Orde de mission'}],
    ['NAT_FERM_PREVUE', {nature: 'Fermeture des tunnels'}],
    // ['NAT_FERM_INOPINEE', {nature: 'Tunnel fermeture inopinée'}],
    ['NAT_E_DIVERS', {nature: ''}],
    ['_KO_', {nature: ''}],
    ['_A_REGR_', {nature: ''}],
  ]);

  empriseMapping = new Map([
    ['E_CLOSE_LANE', {
      style_emprise: {color: COLORS.E_CLOSE_LANE, opacity: 1.0, weight: 2, fillColor: COLORS.E_CLOSE_LANE, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.E_CLOSE_LANE, opacity: 0.70, weight: 2, fillColor: COLORS.E_CLOSE_LANE, fillOpacity: 0.3},
      description: 'Fermeture complète d\'une ou plusieurs routes'
    }],
    ['E_HALF_CLOSE_LANE', {
      style_emprise: {color: COLORS.E_HALF_CLOSE_LANE, opacity: 1.0, weight: 2, fillColor: COLORS.E_HALF_CLOSE_LANE, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.E_HALF_CLOSE_LANE, opacity: 0.70, weight: 2, fillColor: COLORS.E_HALF_CLOSE_LANE, fillOpacity: 0.3},
      description: 'Fermeture partielle d\'une ou plusieurs routes'
    }],
    ['E_MANIF', {
      style_emprise: {color: COLORS.E_MANIF, opacity: 1.0, weight: 2, fillColor: COLORS.E_MANIF, fillOpacity: 0.70},
      style_impact_halo: {color: COLORS.E_MANIF, opacity: 0.70, weight: 2, fillColor: COLORS.E_MANIF, fillOpacity: 0.3},
      description: 'Manifestation'
    }]
  ]);

  getDataCategory(category: string): string {
    if (this.categoryMapping.get(category)) {
      return this.categoryMapping.get(category).category;
    } else {
      const res = `${category}` + ' :categorie introuvable';
      // console.log(res);
      return res;
    }
  }

  convertCatCodeToCatName(categoriesCode: string[]): string[] {
    const res: string[] = [];
    categoriesCode.map(el => {
      this.categoryMapping.forEach((v, k) => {
        if (el === k) {
          res.push(v.category);
        }
      });
    });
    return res;
  }

  getDataNature(nature: string): string {
    if (nature) {
      if (this.natureMapping.get(nature.trim())) {
        return this.natureMapping.get(nature.trim()).nature;
      } else {
        const res = `${nature}` + ' :nature introuvable';
        // console.log(res);
        return '';
      }
    } else {
      return '';
    }
  }

  getCategories() {
    const keys: string[] = [];
    this.categoryMap.forEach((value, key, map) => {
      keys.push(key);
    });
    return keys;
  }

  getCategoryMarkerHoverUrl(category: string) {
    return this.categoryMap.get(category).markerHoverUrl;
  }

  getCategoryMarkerHoverRiskUrl(category: string) {
    return this.categoryMap.get(category).markerHoverRisqueUrl;
  }

  getCategoryMarkerUrl(category: string) {
    return this.categoryMap.get(category).markerUrl;
  }

  getCategoryMarkerRiskUrl(category: string) {
    return this.categoryMap.get(category).riskMarkerUrl;
  }

  getCategoryProvisionalMarkerHoverUrl(category: string) {
    return this.categoryMap.get(category).provMarkerHoverUrl;
  }

  getCategoryProvisionalMarkerHoverRiskUrl(category: string) {
    return this.categoryMap.get(category).provMarkerHoverRisqueUrl;
  }

  getCategoryProvisionalMarkerUrl(category: string) {
    return this.categoryMap.get(category).provMarkerUrl;
  }

  getCategoryProvisionalMarkerRiskUrl(category: string) {
    return this.categoryMap.get(category).provRiskMarkerUrl;
  }

  getCategoryBDColor(category: string) {
    return this.categoryMap.get(category).color;
  }

  getCategoryFontColor(category: string) {
    return this.categoryMap.get(category).font_color;
  }

  getCategoriesColor(categories: string[]) {
    return categories.map(cat => this.categoryMap.get(cat) ? this.categoryMap.get(cat).color : null);
  }

  getCategoryImpactIconUrl(category: string) {
    return this.categoryMap.get(category).impactMarkerUrl;
  }

  getMapEmpriseStyle(category: string) {
    return this.categoryMap.get(category).style_emprise;
  }

  getMapImpactHaloStyle(category: string) {
    return this.categoryMap.get(category).style_impact_halo;
  }

  getCategoryChecked(category: string) {
    return this.categoryMap.get(category).checked;
  }

  getcategories(): string[] {
    return Array.from(this.categoryMap.keys());
  }

  getCategoryRadius(category: string, nameEvent: string) {
    const r = this.categoryMap.get(category).radius;
    if (nameEvent.includes('14 juillet') && nameEvent.includes('feu d\'artifice')) {
      // r *= 5;
      // console.log(category);
      // console.log(nameEvent);
      // console.log(r);
    }
    return r;
  }

  getLegend() {
    const legends: Legend[] = [];
    this.categoryMap.forEach((categoryObj, category) => {
      legends.push(new Legend(categoryObj.markerUrl, category, categoryObj.def));
    });
    return legends;
  }

  getCategoryIconUrl(category: string) {
    return this.categoryMap.get(category).iconUrl;
  }

  // Les categories pour DB
  getCategoryForDashboard(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'E' || v.typeFlag === 'T' && k !== 'Travaux (permis de construire)') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories evts
  getCategoryEvts(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'E') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories occupation
  getCategoryOccupations(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'OCC') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories faits
  getCategoryFaits(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'F') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories mission
  getCategoryMissions(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'M') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories fermeture des tunnels
  getCategoryFermetureTunnels(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'U') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories fermeture des voies
  getCategoryFermetureVoies(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'V') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories travaux
  getCategoryTravaux(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'T') {
        lst.push(k);
      }
    });
    return lst;
  }

  // Que les categories Trafic
  getCategoryTrafic(): string[] {
    const lst = [];
    this.categoryMap.forEach((v, k) => {
      if (v.typeFlag === 'TR') {
        lst.push(k);
      }
    });
    return lst;
  }

  getCategoryIconSize(category: string): number {
    return this.categoryMap.get(category).iconSize;
  }

  getCategoryChargeIsByDefault(byDefault: boolean) {
    const categorysChargeDefault = [];
    const categorysChargeSecondTemp = [];
    this.categoryMap.forEach((v, k) => {
      if (v.checked) {
        categorysChargeDefault.push(k);
      } else {
        categorysChargeSecondTemp.push(k);
      }
    });
    return byDefault ? this.convertCategory(categorysChargeDefault) : this.convertCategory(categorysChargeSecondTemp);
  }

  convertCategory(categories: string[]): string[] {
    const res: string[] = [];
    categories.map(el => {
      this.categoryMapping.forEach((v, k) => {
        if (el === v.category) {
          res.push(k);
        }
      });
    });
    return res;
  }

  // faits en version serveur
  getCategoriesFactsForServer() {
    return this.convertCategory(this.getCategoryFaits());
  }

  getAllCategoryForServer() {
    return this.convertCategory(this.getCategories());
  }

  getAllCategoryForServerSansTravaux() {
    return this.convertCategory(this.getCategoryForDashboard());
  }

  getCategoryEvtsForServer() {
    return this.convertCategory(this.getCategoryEvts());
  }

  getCategoriesForServer(firstCharge: boolean, categories: string[]) {
    if (firstCharge) {
      return this.convertCategory(categories).filter(el => this.categoryMap.get(el).checked);
    } else {
      return this.convertCategory(categories).filter(el => !this.categoryMap.get(el).checked);
    }
  }

  getEmpriseDescription(empriseId: string): string {
    return this.empriseMapping.get(empriseId).description;
  }

  getStyleEmprise(laneClosureDisplay: boolean, empriseId: string, categorie: string) {
    if (laneClosureDisplay || empriseId === 'E_MANIF') {
      return this.empriseMapping.get(empriseId) !== undefined ? this.empriseMapping.get(empriseId).style_emprise : this.getMapEmpriseStyle(categorie);
    }
    return this.getMapEmpriseStyle(categorie);
  }

  getEmpriseStyleImpactHalo(empriseId: string) {
    return this.empriseMapping.get(empriseId).style_impact_halo;
  }
}
