import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DoCheck, OnInit } from '@angular/core';
import { ControllerService } from 'src/app/services/controller.service';
import { LegendDataSource } from 'src/app/services/legend.datasource';
import { Legend } from '../../models/legend.model';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LegendComponent implements OnInit, DoCheck {
  legends: LegendDataSource;
  isFocusOnIcon: boolean;
  isFocusOnTable: boolean;
  legendNbr: number;
  columnsToDisplay = ['pictogramme', 'categorie'];

  legendClicked: Legend | null;

  constructor(private controllerService: ControllerService) {}

  ngOnInit() {
    this.legends = new LegendDataSource();
    this.legends.loadingLegend();
  }

  ngDoCheck() {
    this.isFocusOnIcon = this.controllerService.showLegendIcon;
  }

  isShowTable() {
    return this.isFocusOnIcon || this.isFocusOnTable;
  }

  interceptGroupInfo(expandedElement: Legend, element: Legend) {
    return expandedElement === element ? null : element;
  }
}
