import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {retry, takeUntil} from 'rxjs/operators';
import {GROUP, SECTEURS} from '../constants/list.const';
import {PlageTemporelle} from '../constants/plage.temporelle';
import {REQUETE_TIMER} from '../constants/time.interval';
import {DatePair} from '../models/date.pair.model';
import {Event} from '../models/event.model';
import {Filter} from '../models/filter.model';
import {CategoryUtil} from '../utils/category.util';
import {AppConfigService} from './app.config.service';
import {ControllerService} from './controller.service';
import {DateService} from './date.service';
import {FilterService} from './filter.service';
import {CameraCoverage} from '../models/coverage.model';
import {Incomp} from '../models/incomp.model';
import {environment} from '../../environments/environment';
import {RealtimeTrafficService} from './realtime-trafic.service';
import moment from 'moment';

interface PostData {
  from: Date;
  to: Date;
  minioutput: boolean;
  categories: string[];
}

interface TrafficData {
  status: string;
  jams: any;
  alerts: any;
}

let httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DataService implements OnDestroy {
  public fulldayData: Event[] = [];
  public fullDayDataSubject: Subject<Event[]> = new Subject<Event[]>();

  public filteredData: Event[] = [];
  public filteredDataSubject: Subject<Event[]> = new Subject<Event[]>();

  public cameraCoverageData: CameraCoverage[] = [];
  public camaraCoverageSubject: Subject<CameraCoverage[]> = new Subject<CameraCoverage[]>();

  public incompData: Incomp[] = [];
  public incompEvtIds: string[] = [];
  public incompSubject: Subject<Incomp[]> = new Subject<Incomp[]>();

  public trafficDataSubject: Subject<TrafficData> = new Subject<TrafficData>();
  public filteredTrafficDataSubject: Subject<TrafficData> = new Subject<TrafficData>();
  private filteredTrafficData: TrafficData;

  public filterSubscription: Subscription;
  public rolesSubscription: Subscription;

  private categoryUtil = new CategoryUtil();
  public filter: Filter = new Filter();
  private fromtoDate = new DatePair();

  public dateImpMax: Date;
  private postData: PostData;
  private allCategories = this.categoryUtil.getAllCategoryForServer();
  private apiCallUnsubscribe: Subject<void> = new Subject<void>();
  public isBackendDown: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private filterService: FilterService,
    private controlService: ControllerService,
    private _realtimeTrafficService: RealtimeTrafficService,
    private appConfigService: AppConfigService
  ) {
    setInterval(() => {
      this.initDayData();
    }, REQUETE_TIMER.FREQUENCE * 60 * 1000);
  }

  emitFilteredData() {
    // when user filter data, disable the animations;
    this.controlService.disableAnimation = true;
    this.filteredData.sort(
      (litte, big) => new Date(big.horodateDebut).getTime() - new Date(litte.horodateDebut).getTime(),
    );

    this.filteredDataSubject.next(this.filteredData.slice());
  }

  emitFilteredTrafficData() {
    this.trafficDataSubject.next(Object.assign({}, this.filteredTrafficData));
  }

  emitCameraCoverageData() {
    this.camaraCoverageSubject.next(this.cameraCoverageData.slice());
  }

  emitIncompData() {
    this.incompSubject.next(this.incompData.slice());
  }

  emitFullDayData() {
    this.fulldayData = this.fulldayData.sort(
      (litte, big) => new Date(big.horodateDebut).getTime() - new Date(litte.horodateDebut).getTime()
    );
    this.fullDayDataSubject.next(this.fulldayData.slice());
  }

  initDayData() {
    this.isBackendDown.next(false);
    if (this.dateService.date) {
      this.requestFromTo(this.dateService.date, 0, 0, 5, 59, false);
    } else {
      this.requestFromTo(new Date(), 0, 0, 5, 59, false);
    }
  }

  setDate(date: Date) {
    this.dateService.date = date;
    this.requestFromTo(date, 0, 0, 5, 59, true);
    // when change date, close the clicked markers;
    this.controlService.emitEvtDetailState(false);

  }

  requestFromTo(
    date: Date,
    fromHour: number,
    fromMinute: number,
    toHour: number,
    toMinute: number,
    isDateChange: boolean,
  ) {
    // Vider les données dans la carte;
    this.filteredData = [];
    this.emitFilteredData();
    // faire apparaître le spinner,
    this.fulldayData = [];
    this.emitFullDayData();
    let res: DatePair = null;
    this.fromtoDate = this.dateService.buildFromToDate(date, fromHour, fromMinute, toHour, toMinute);
    if (this.filter) {
      if (this.filter && this.filter.plageTemporelle) {
        res = this.dateService.setFilterTimeInterval(this.fromtoDate.fromDate, this.filter.plageTemporelle);
      } else {
        res = this.dateService.setFilterTimeInterval(this.fromtoDate.fromDate, PlageTemporelle.Jour);
      }
    }
    let categories: string[];
    if (isDateChange) {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      this.filterService.keepFilterState(date);
      categories = this.categoryUtil.convertCategory(this.filter.categories.slice());
    } else {
      categories = this.categoryUtil.getCategoryChargeIsByDefault(true);
      this.filterService.setFilterFromToDate(res.fromDate, res.toDate);
    }
    this.postData = {
      from: this.fromtoDate.fromDate,
      to: this.fromtoDate.toDate,
      minioutput: true,
      categories,
    };

    if (environment.production) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          Authorization: this.appConfigService.getAppConfig().local_authorization_access,
        })
      };
    }

    this.postData = {
      from: this.fromtoDate.fromDate,
      to: this.fromtoDate.toDate,
      minioutput: true,
      categories
    };

    this.http.post<Event[]>(this.appConfigService.getAppConfig().base_api_url + this.appConfigService.getAppConfig().api_url_evenements, this.postData, httpOptions)
      .pipe(retry(3)) //// retry a failed request up to 3 times;
      .pipe(takeUntil(this.apiCallUnsubscribe))
      .subscribe( {
      	next: response => {
	this.fulldayData = response.map(el => new Event(this.convertEvent(el)));
        // this.fulldayData.push(this.FAKE_DATA);
        // this.sendSecondChargement(isDateChange);
        this.emitFullDayData();
        if (this.filterSubscription) {
          this.filterSubscription.unsubscribe();
        }
        this.filterSubscription = this.filterService.filterSubject.subscribe(filter => {
          //console.log('info filter:',JSON.stringify(filter));
          this.filter = filter;
          this.applyFilter(this.filter);

        });
        },
        error: e => {
          this.isBackendDown.next(true);
        }
      });
  }

  convertEvent(evt: Event): Event {
    evt.type = this.categoryUtil.getDataCategory(evt.categorie) + '\n' + evt.type;
    evt.categorie = this.categoryUtil.getDataCategory(evt.categorie);
    return evt;
  }

  sendSecondChargement(isDateChange: boolean) {
    const categorieChoosen = this.categoryUtil.convertCategory(this.filter.categories.slice());
    const categoriesNoChoosen = this.allCategories.filter((ca) => !categorieChoosen.includes(ca));
    this.postData.categories = isDateChange
      ? categoriesNoChoosen
      : this.categoryUtil.getCategoryChargeIsByDefault(isDateChange);
    this.http.post<Event[]>(this.appConfigService.getAppConfig().base_api_url + this.appConfigService.getAppConfig().api_url_evenements, this.postData, httpOptions)
      .pipe(retry(3))
      .pipe(takeUntil(this.apiCallUnsubscribe))
      .subscribe((data) => {
        data = data.map((el) => new Event(this.convertEvent(el)));
        this.fulldayData.push(...data);
        this.filteredData.push(...this.filterFunc(this.filter, data));
        this.emitFilteredData();
      });
  }

  applyFilter(filter: Filter): void {
    this.filteredData = this.filterFunc(filter, this.fulldayData);
    this.emitFilteredData();
  }

  filterFunc(filter: Filter, evts: Event[]): Event[] {
    const filteredEvts = evts
      .filter(el => new Date(el.horodateDebut) <= new Date(filter.toDate) && new Date(filter.fromDate) <= new Date(el.horodateFin))
      .filter(el => filter.secteurs.includes(el.secteur) ||
        (filter.secteurs.includes(SECTEURS.LITTO) && el.isLittoral.trim().toLowerCase() === 'true'))
      .filter(el => {
        if (filter.categories.includes(el.categorie)) {
            return true;
          }

        if (filter.categories.includes(GROUP.FERMETURES) || filter.categories.includes(GROUP.FERMETURES_VOIES)) {
          return (el.laneClosureStatus === 'E_CLOSE_LANE' || el.laneClosureStatus === 'E_HALF_CLOSE_LANE') ? true : false;
        }
        else if (filter.categories.includes('Fermeture partielle')) {
          return el.laneClosureStatus === 'E_HALF_CLOSE_LANE' ? true : false;
        }
        else if (filter.categories.includes('Fermeture complète')) {
          return el.laneClosureStatus === 'E_CLOSE_LANE' ? true : false;
        }
        else {
          return false;
        }
      })
      .filter(el => filter.incompChecked ? this.incompEvtIds.includes(el.uid) : true)
      .filter(el => filter.provisoireChecked ? el.statut === 'EVT_A_VALIDER' : true);
    return filteredEvts;
  }

  filterTrafficData(filter: Filter, trafficData: TrafficData) {
    const filteredAlertsData = this.filterAlert(filter, trafficData.alerts);
    return {status: trafficData.status, jams: trafficData.jams, alerts: filteredAlertsData};
  }

  filterAlert(filter: Filter, alerts: Event[]): Event[] {
    return alerts.filter(el => filter.categories.includes(el.categorie));
  }

  dateFormatter(date?: Date): DatePair {
    const fromTo = new DatePair();
    if (date) {
      fromTo.fromDate = new Date(date);
      fromTo.toDate = new Date(date);
    } else {
      fromTo.fromDate = new Date();
      fromTo.toDate = new Date();
    }
    fromTo.fromDate.setHours(0);
    fromTo.fromDate.setMinutes(1);

    fromTo.toDate.setDate(fromTo.toDate.getUTCDate());
    fromTo.toDate.setHours(5);
    fromTo.toDate.setMinutes(59);
    return fromTo;
  }

  emitTrafficData(data) {
    console.log(`
      =================
      ${moment().format( 'LLL')}
      Nouveaux données de trafic : ${data.jams.length} jams, ${data.alerts.length} alerts
      =================
    `);
    this.trafficDataSubject.next(data);
  }

  formatedTrafficData(data) {
    return {status: data.status, jams: data.jams, alerts: data.alerts.map(el => this.convertTrafficAlert(el))};
  }

  private convertTrafficAlert(trafficAlert: Event): Event {
    trafficAlert.categorie = this.categoryUtil.getDataCategory(trafficAlert.categorie);
    return trafficAlert;
  }

  cancelApiRequest() {
    this.apiCallUnsubscribe.next();
    this.apiCallUnsubscribe.complete();
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
    this.rolesSubscription.unsubscribe();
  }
}
