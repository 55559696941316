/* Library Angular */
import {Component, DoCheck, OnDestroy, OnInit, ViewChild,EventEmitter,  Output} from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import * as L from 'leaflet';
/* Library third party */
import {Options} from 'ng5-slider';
import {PlageTemporelle} from 'src/app/constants/plage.temporelle';
import {Ranger} from 'src/app/models/ranger.model';
import {ControllerService} from 'src/app/services/controller.service';
import {DateService} from 'src/app/services/date.service';
/* Services */
import {FilterService} from 'src/app/services/filter.service';
import {MenuStateService} from 'src/app/services/menu-state.service';
import {GROUP, SECTEURS} from '../../constants/list.const';
import {SECTEUR_CENTRE} from '../../constants/map.control.const';
import {CategoryUtil} from '../../utils/category.util';
/* Component & Model & Util */
import {HeaderCalendarComponent} from './header-calendar/header-calendar.component';
import {HeaderDayComponent} from './header-day/header-day.component';
import {KeyValue} from '@angular/common';
import {MobileStateService} from 'src/app/services/mobile-state.service';
import {
  MatSelectionList
} from "@angular/material/list";
import { FormBuilder } from '@angular/forms';
import {Subscription} from 'rxjs';
interface CHOIX {
  change: number;
  diff: string;
}

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.css'],
})
export class HeadersComponent implements OnInit, DoCheck {

/*private categoryEvtEl:MatSelectionList;
  @ViewChild('categoryEvt', {static: false}) set categoryEvt(elRef: MatSelectionList) {
this.categoryEvtEl=elRef;
this.categoryEvtEl.selectAll();
  }*/


  @ViewChild(HeaderCalendarComponent, {static: true})
  private headerCalendarComponent: HeaderCalendarComponent;
  @ViewChild(HeaderDayComponent, {static: true})
  private headerDayComponent: HeaderDayComponent;

  @Output()
  hideFilter: EventEmitter<any> = new EventEmitter();

  GROUP = GROUP;
  SECTEURS = SECTEURS;

  private categoryUtil = new CategoryUtil();
  originalCategoryList: string[] = this.categoryUtil.getCategories();

  categoryFormControl = new FormControl();
  categorySelected: string[] = this.originalCategoryList.filter(item => this.categoryUtil.getCategoryChecked(item));
  categoryMobileSelected: string[] = this.originalCategoryList.filter(item => this.categoryUtil.getCategoryChecked(item));

  secteursFormControl = new FormControl();
  orginalSecteursList: string[] = [SECTEURS.TOUS, SECTEURS.NORD, SECTEURS.CENTRE, SECTEURS.SUD, SECTEURS.LITTO];
  secteurSelected: string[] = this.orginalSecteursList.slice();

  showImportance = false;
  showDialog = false;

  optionsRisques: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
  };

  suivi = false;
  defaultCharge = true;
  nbFiltres=0;
  categoryTous:  MatSelectionList;
  // ajoute choix pour grouper évenement,faits,missions,
  evtsList = this.categoryUtil.getCategoryEvts();
  occupationsList = this.categoryUtil.getCategoryOccupations();
  travauxList = this.categoryUtil.getCategoryTravaux();
  fermeturesTunnelsList = this.categoryUtil.getCategoryFermetureTunnels();
  fermeturesVoiesList = this.categoryUtil.getCategoryFermetureVoies();
  groups: string[] = [];
  // pour btn aujourd'hui
  isToday = true;
  labelPosition = 'before';
  panelOpenState = true;
  incompChecked: boolean;
  provisoireChecked = false;
  deviceMobile : boolean = false;
  groupsv:  { [key: string]: string[] } ;
  filterForm:FormGroup;
  private hSubscription: Subscription;
  //affichageList=['Densité','Emprises','Pictogrammes'];
  constructor(
    private menuStateService: MenuStateService,
    private filterService: FilterService,
    private dateService: DateService,
    private controllerService: ControllerService,
    private mobileStateService: MobileStateService,
    private formBuilder: FormBuilder
  )
  {

    // mettons les valeurs cochés par défault pour filtres:
    this.categorySelected.push(GROUP.TOUS, GROUP.EVENEMENT, GROUP.OCCUPATIONS, GROUP.TRAVAUX, GROUP.FERMETURES, GROUP.FERMETURES_VOIES);
    this.updateFilters(this.categorySelected, this.secteurSelected);

    /************* Mobile*****************/


    // Create a FormControl for the select/unselect all checkbox
    const selectAllControl = new FormControl(false);

      this.filterForm = this.formBuilder.group({
          categoryEvtForm: new FormControl(false),
          categoryOccupationForm: new FormControl(false),
          categoryTravauxForm: new FormControl(false),
          categoryFermetureForm: new FormControl(false),
          categoryFermetureVoiesForm: new FormControl(false),
          selectAll: selectAllControl
        });




  }

  ngOnInit() {
    this.addSubGroups();
    // init filter instance avec natures et secteurs;

    this.filterService.setFilterCategory(this.categorySelected);
    this.filterService.setFilterSecteurs(this.secteurSelected);

    this.filterService.setFilterProvisoire(this.provisoireChecked);
    /************Mobile *********/
    this.hSubscription=this.mobileStateService.subscribeToLayoutChanges().subscribe(observerResponse => {

      this.deviceMobile = this.mobileStateService.isMobile();
      if(this.deviceMobile){

         this.categorySelected = this.filterService.getFilterNatures();

          this.majMobilefiltres( this.categorySelected );
          this.nbFiltres = this.calculNbFiltre();
          if(this.nbFiltres == this.categoryUtil.getCategories().length){
            this.filterForm.get('selectAll').patchValue(true,{ emitEvent: false });
          }

          //maj des checkbox et du nb de filtres
        this.filterForm.get('selectAll').valueChanges.subscribe(bool => {

          if(bool){
            this.activeAllFiltreMobile();
          }else{
            this.desactiveAllFiltreMobile();
          }
          this.nbFiltres = this.calculNbFiltre();

        });


      }

    });

  }

  activeAllFiltreMobile(){
    this.filterForm
          .get('categoryEvtForm')
          .patchValue(this.evtsList, { emitEvent: false });
          this.filterForm
          .get('categoryOccupationForm')
          .patchValue(this.occupationsList, { emitEvent: false });
          this.filterForm
          .get('categoryTravauxForm')
          .patchValue(this.travauxList, { emitEvent: false });

          this.filterForm
          .get('categoryFermetureForm')
          .patchValue(this.fermeturesTunnelsList, { emitEvent: false });
          this.filterForm
          .get('categoryFermetureVoiesForm')
          .patchValue(this.fermeturesVoiesList, { emitEvent: false });
  }


  desactiveAllFiltreMobile(){
    this.filterForm
    .get('categoryEvtForm')
    .patchValue([], { emitEvent: false });
    this.filterForm
    .get('categoryOccupationForm')
    .patchValue([], { emitEvent: false });
    this.filterForm
    .get('categoryTravauxForm')
    .patchValue([], { emitEvent: false });

    this.filterForm
    .get('categoryFermetureForm')
    .patchValue([], { emitEvent: false });
    this.filterForm
    .get('categoryFermetureVoiesForm')
    .patchValue([], { emitEvent: false });
  }

  majMobilefiltres( categorySelected ){

    this.filterForm
    .get('categoryEvtForm')
    .patchValue(this.evtsList.filter(item => categorySelected.includes(item)), { emitEvent: false });
    this.filterForm
    .get('categoryOccupationForm')
    .patchValue(this.occupationsList.filter(item => categorySelected.includes(item)), { emitEvent: false });
    this.filterForm
    .get('categoryTravauxForm')
    .patchValue(this.travauxList.filter(item => categorySelected.includes(item)), { emitEvent: false });
    this.filterForm
    .get('categoryFermetureForm')
    .patchValue(this.fermeturesTunnelsList.filter(item => categorySelected.includes(item)), { emitEvent: false });
    this.filterForm
    .get('categoryFermetureVoiesForm')
    .patchValue(this.fermeturesVoiesList.filter(item => categorySelected.includes(item)), { emitEvent: false });


  }

  /*
  *Il faut que tous les faits commence par 'Atteinte';
  *Les faits sont collé, l'un après l'autre;
  */
  addSubGroups() {
    // ajoute Tous et Events
    this.originalCategoryList.unshift(GROUP.TOUS, GROUP.EVENEMENT);

    // ajoute Occupations
    const firstIndexOccupations = this.originalCategoryList.findIndex( category => category.startsWith('Divers')) + 1;
    this.originalCategoryList.splice(firstIndexOccupations, 0, GROUP.OCCUPATIONS);

    // ajoute Travaux
    const firstIndexTravaux = this.originalCategoryList.findIndex( category => category.startsWith('divers')) + 1;
    this.originalCategoryList.splice(firstIndexTravaux, 0, GROUP.TRAVAUX);

    // ajoute Fermetures
    const firstIndexFermetures = this.originalCategoryList.findIndex( category => category.toLowerCase().includes('tunnels'));
    this.originalCategoryList.splice(firstIndexFermetures, 0, GROUP.FERMETURES);

    const firstIndexFermeturesVoies = this.originalCategoryList.findIndex( category => category.toLowerCase().includes('tunnels')) + 1;
    this.originalCategoryList.splice(firstIndexFermeturesVoies, 0, GROUP.FERMETURES_VOIES);

  }

  ngDoCheck() {
    //
  }

  onChooseCategory() {

      const choix = this.getArrDiff(this.categorySelected, this.categoryFormControl.value);
      this.categorySelected = this.categoryFormControl.value;
      if (choix.diff === GROUP.TOUS) {
        if (choix.change === 1) {
          const tmp = this.originalCategoryList.slice();
          tmp.push(...[GROUP.TOUS, GROUP.EVENEMENT, GROUP.OCCUPATIONS, GROUP.TRAVAUX, GROUP.FERMETURES, GROUP.FERMETURES_VOIES]);
          this.categorySelected = tmp;
        } else {
          this.categorySelected = [];
        }
      } else {
        if (choix.diff === GROUP.EVENEMENT || choix.diff === GROUP.OCCUPATIONS || choix.diff === GROUP.TRAVAUX || choix.diff === GROUP.FERMETURES || choix.diff === GROUP.FERMETURES_VOIES) {
            this.affectDiff(choix);
        }

        if (this.categorySelected.includes(GROUP.TOUS)) {
          this.categorySelected = this.categorySelected.filter(el => el !== GROUP.TOUS);
        }

        if (choix.change === 0) {// décocher
          if (this.evtsList.includes(choix.diff)) {
            if (this.categorySelected.includes(GROUP.EVENEMENT)) {
             this.categorySelected = this.categorySelected.filter(el => el !== GROUP.EVENEMENT);
            }
          }
          if (this.occupationsList.includes(choix.diff)) {
            if (this.categorySelected.includes(GROUP.OCCUPATIONS)) {
             this.categorySelected = this.categorySelected.filter(el => el !== GROUP.OCCUPATIONS);
            }
          }
          if (this.travauxList.includes(choix.diff)) {
            if (this.categorySelected.includes(GROUP.TRAVAUX)) {
             this.categorySelected = this.categorySelected.filter(el => el !== GROUP.TRAVAUX);
            }
          }
          if (this.fermeturesTunnelsList.includes(choix.diff)) {
            if (this.categorySelected.includes(GROUP.FERMETURES)) {
              this.categorySelected = this.categorySelected.filter(el => el !== GROUP.FERMETURES);
            }
          }
          if (this.fermeturesVoiesList.includes(choix.diff)) {
            if (this.categorySelected.includes(GROUP.FERMETURES_VOIES)) {
              this.categorySelected = this.categorySelected.filter(el => el !== GROUP.FERMETURES_VOIES);
            }
            if (this.categorySelected.includes(GROUP.FERMETURES)) {
              this.categorySelected = this.categorySelected.filter(el => el !== GROUP.FERMETURES);
            }
          }
        } else {// cocher
          if (this.evtsList.includes(choix.diff) && this.evtsList.every(el => this.categorySelected.includes(el))) {
            if (!this.categorySelected.includes(GROUP.EVENEMENT)) { this.categorySelected.push(GROUP.EVENEMENT); }
          }

          if (this.occupationsList.includes(choix.diff) && this.occupationsList.every(el => this.categorySelected.includes(el))) {
            if (!this.categorySelected.includes(GROUP.OCCUPATIONS)) { this.categorySelected.push(GROUP.OCCUPATIONS); }
          }

          if (this.travauxList.includes(choix.diff) && this.travauxList.every(el => this.categorySelected.includes(el))) {
            if (!this.categorySelected.includes(GROUP.TRAVAUX)) { this.categorySelected.push(GROUP.TRAVAUX); }
          }

          if (this.fermeturesTunnelsList.includes(choix.diff) && this.fermeturesTunnelsList.every(el => this.categorySelected.includes(el))) {
            if (this.categorySelected.includes(GROUP.FERMETURES_VOIES)) {
              if (!this.categorySelected.includes(GROUP.FERMETURES)) {
                this.categorySelected.push(GROUP.FERMETURES);
              }
            }
          }

          if (this.fermeturesVoiesList.includes(choix.diff) && this.fermeturesVoiesList.every(el => this.categorySelected.includes(el))) {
            if (!this.categorySelected.includes(GROUP.FERMETURES_VOIES)) {
              this.categorySelected.push(GROUP.FERMETURES_VOIES);
              if (this.fermeturesTunnelsList.every(el => this.categorySelected.includes(el))) {
                if (!this.categorySelected.includes(GROUP.FERMETURES)) {
                  this.categorySelected.push(GROUP.FERMETURES);
                }
              }
            }
          }

        }
      }

      // lorsqu'il contient tous les choix, cocher aussi le tous;
      if (this.categorySelected.includes(GROUP.EVENEMENT) &&
          this.categorySelected.includes(GROUP.OCCUPATIONS) &&
          this.categorySelected.includes(GROUP.TRAVAUX) &&
          this.categorySelected.includes(GROUP.FERMETURES) &&
          this.categorySelected.includes(GROUP.FERMETURES_VOIES)
          ) {
          this.categorySelected.push(GROUP.TOUS);
      }
      this.updateCategoryFilters(this.categorySelected);
      this.filterService.setFilterCategory(this.categorySelected);
  }

  // find the difference (add or delete) in comparing the last choice and current choice
  affectDiff(choix: CHOIX) {
    if (choix.change === 1) {
      switch (choix.diff) {
        case GROUP.EVENEMENT:
          this.categorySelected.push(...this.evtsList); break;
        case GROUP.OCCUPATIONS:
          this.categorySelected.push(...this.occupationsList); break;
        case GROUP.TRAVAUX:
          this.categorySelected.push(...this.travauxList); break;
        case GROUP.FERMETURES:
            this.categorySelected.push(...this.fermeturesTunnelsList);
            this.categorySelected.push(GROUP.FERMETURES_VOIES);
            this.categorySelected.push(...this.fermeturesVoiesList);
            break;
        case GROUP.FERMETURES_VOIES:
          this.categorySelected.push(...this.fermeturesVoiesList);
          if (this.fermeturesTunnelsList.every(el => this.categorySelected.includes(el))) {
            if (!this.categorySelected.includes(GROUP.FERMETURES)) {
              this.categorySelected.push(GROUP.FERMETURES);
            }
          }
          break;
        default: console.log('groupement non classé'); break;
      }
    } else {
      switch (choix.diff) {
        case GROUP.EVENEMENT:
          this.categorySelected = this.categorySelected.filter(el => !this.evtsList.includes(el)); break;
        case GROUP.OCCUPATIONS:
          this.categorySelected = this.categorySelected.filter(el => !this.occupationsList.includes(el)); break;
        case GROUP.TRAVAUX:
          this.categorySelected = this.categorySelected.filter(el => !this.travauxList.includes(el)); break;
        case GROUP.FERMETURES:
          this.categorySelected = this.categorySelected.filter(el => !this.fermeturesTunnelsList.includes(el));
          this.categorySelected = this.categorySelected.filter(el => GROUP.FERMETURES_VOIES !== el);
          this.categorySelected = this.categorySelected.filter(el => !this.fermeturesVoiesList.includes(el));
          break;
        case GROUP.FERMETURES_VOIES:
          this.categorySelected = this.categorySelected.filter(el => !this.fermeturesVoiesList.includes(el));
          if (this.categorySelected.includes(GROUP.FERMETURES)) {
            this.categorySelected = this.categorySelected.filter(el => GROUP.FERMETURES !== el);
          }
          break;
        default: console.log('groupement non classé'); break;
      }
    }
   }

   calculNbFiltre() :number{

    let nbfiltresCategory=this.nbFiltres= this.filterForm.get('categoryEvtForm').value.length + this.filterForm.get('categoryOccupationForm').value.length + this.filterForm.get('categoryTravauxForm').value.length + this.filterForm.get('categoryFermetureForm').value.length + this.filterForm.get('categoryFermetureVoiesForm').value.length ;

      return nbfiltresCategory ;

    }
   onChooseCategoryMobile(event) {


    this.nbFiltres = this.calculNbFiltre();
    if(this.nbFiltres == this.categoryUtil.getCategories().length){
      this.filterForm.get('selectAll').patchValue(true,{ emitEvent: false });
    }else{
      this.filterForm.get('selectAll').patchValue(false,{ emitEvent: false });
    }

}
onChooseCategoryTous(event){
  this.nbFiltres = this.calculNbFiltre();

}
appliquerFiltre(){
  this.categorySelected = [...this.filterForm.get('categoryEvtForm').value ,...this.filterForm.get('categoryOccupationForm').value,...this.filterForm.get('categoryTravauxForm').value,...this.filterForm.get('categoryFermetureForm').value,...this.filterForm.get('categoryFermetureVoiesForm').value]

  let travaux=this.filterForm.get('categoryTravauxForm').value;

  //il faut ajouter le group TRAVAUX pour afficher les pictos travaux
  if(travaux.length ==this.travauxList.length){
   this.categorySelected.push(GROUP.TRAVAUX);
  }
 let fermeture=0;
  if(this.filterForm.get('categoryFermetureVoiesForm').value.length ==this.fermeturesVoiesList.length){
    this.categorySelected.push(GROUP.FERMETURES_VOIES);
    fermeture++;
   }
   if(this.filterForm.get('categoryFermetureForm').value.length ==this.fermeturesTunnelsList.length){

    fermeture++;
   }

   if(fermeture==2){
    this.categorySelected.push(GROUP.FERMETURES);
   }
  this.filterService.setFilterCategory(this.categorySelected);


  this.hideFilter.emit();
}


  updateCategoryFilters(categorySelected: string[]) {
    this.categoryFormControl.setValue(categorySelected);
  }

  updateSecteurFilters(secteursSelected: string[]) {
    this.secteursFormControl.setValue(secteursSelected);
  }

  updateFilters(categorySelected: string[], secteursSelected: string[]) {
    this.secteursFormControl.setValue(secteursSelected);
    this.categoryFormControl.setValue(categorySelected);
  }

  onChooseSecteur(filterChosen?: any) {
    if (filterChosen) {
      this.secteurSelected = filterChosen;
    } else {
      const choix = this.getArrDiff(this.secteurSelected, this.secteursFormControl.value);
      this.secteurSelected = this.secteursFormControl.value;
      if (choix.diff === GROUP.TOUS) {
        if (choix.change === 1) {
          this.secteurSelected = this.orginalSecteursList;
        } else {
          this.secteurSelected = [];
        }
      } else if (this.secteurSelected.includes(GROUP.TOUS)) {
        this.secteurSelected = this.secteurSelected.filter(el => el !== GROUP.TOUS);
      } else if (!this.secteurSelected.includes(GROUP.TOUS) && this.secteurSelected.length === 4) {
        this.secteurSelected.push(GROUP.TOUS);
      }
    }
    this.updateSecteurFilters(this.secteurSelected);
    this.filterService.setFilterSecteurs(this.secteurSelected);
  }

  getArrDiff(oldArr: string[], newArr: string[]): CHOIX {
    const isAdd = newArr.length - oldArr.length > 0;
    let diffObj: CHOIX;
    if (isAdd) {
      diffObj = {change: 1, diff: newArr.filter(el => !oldArr.includes(el))[0]};
    } else {
      diffObj = {change: 0, diff: oldArr.filter(el => !newArr.includes(el))[0]};
    }
    return diffObj;
  }

  showImportanceDiv(): void {
    this.showImportance = !this.showImportance;
    this.showDialog = !this.showDialog;
  }

  onSuivi(suivi: boolean): void {
    // console.log("parent is informed;"+suivi);
    this.suivi = suivi;
    if (this.suivi) {
      this.isToday = true;
      this.headerCalendarComponent.onSuiviDateChange();
    }
  }

  onSuiviCalendar(suivi: boolean): void {
    // mise à jour le couleur du btn d'aujourd'hui,
    this.isToday = false;
    this.suivi = suivi; // change color of header border;
    if (this.suivi) {
      this.isToday = true;
      this.headerCalendarComponent.onSuiviDateChange();
    } else {
      this.headerDayComponent.colorDefiner = this.menuStateService.menuStates;
      this.headerDayComponent.deleteTimers();
    }
  }

  onClickedOutside(e: Event): void {
    this.showImportance = false;
  }

  gotoToday() {
    this.dateService.date = new Date();
    this.isToday = true;
    this.headerCalendarComponent.onSuiviDateChange();
  }

  incompChanging(e) {
    if (e) {
      this.filterService.setFilterIncomp(e.checked);
      this.incompChecked = e.checked;
    }
  }

  provisoireChanging(e) {
    if (e) {
      this.filterService.setFilterProvisoire(e.checked);
      this.provisoireChecked = e.checked;
    }
  }

  checkIfTrafficCat(cat, trafficCats) {
    return trafficCats.includes(cat);
  }

  // Preserve original property order
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  ngOnDestroy() {
    this.hSubscription.unsubscribe();
  }

}
