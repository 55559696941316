/* Library Angular */
import {Component, OnDestroy, OnInit, ViewChild,AfterViewInit, ElementRef, Renderer2, Output, EventEmitter} from '@angular/core';
/* Library third party */
import * as L from 'leaflet';
import * as geojson from 'geojson';
import * as LC from 'leaflet.markercluster';
import {Subject, Subscription} from 'rxjs';
import {MESSAGE} from 'src/app/constants/message.const';
import {AppConfigService} from 'src/app/services/app.config.service';
import * as d3 from 'd3';
import * as moment from 'moment';
/* Services */
import {ControllerService} from 'src/app/services/controller.service';
import {DataService} from 'src/app/services/data.service';
import {FilterService} from 'src/app/services/filter.service';
import {SigService} from 'src/app/services/sig.service';
import HeatmapOverlay from '../../../assets/scripts/leaflet-heatmap.js';
import {MAP_CONTROL_STYLE} from '../../constants/map.control.const';
/* Util/Modèle */
import {BADGE_CONF, HEATMAPConf, MARKER_CONF, OBDTPMDefs} from '../../constants/url.const';
import {Event} from '../../models/event.model';
import {HeatPoint} from '../../models/heat.point.model';
import {CategoryUtil, CLUSTER_RADIUS} from '../../utils/category.util';
import * as proj4 from './../../../assets/scripts/proj4leaflet';
import {LegendComponent} from '../legend/legend.component';
import {Filter} from 'src/app/models/filter.model.js';
import {takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { GROUP } from 'src/app/constants/list.const.js';
import {MobileStateService} from 'src/app/services/mobile-state.service';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { addListener } from 'process';
//import { EventEmitter } from 'protractor';
enum LAYERS_NAMES {
  Marker = 'Pictogrammes',
  Emprise = 'Emprises',
  HeatMap = 'Densité',
  Occupation = 'Éléments d\'Occupation',
}

enum L_CLUSTER {
  PaneEvts = 'cluster-pane-evts',
  PaneOcc = 'cluster-pane-occ'
}

interface TileLimit {
  [key: number]: { x: number; y: number };
}

const LOADING_LIMIT_WMTS: TileLimit = {
  0: {y: 5, x: 8},
  1: {y: 7, x: 10},
  2: {y: 9, x: 14},
  3: {y: 14, x: 21},
  4: {y: 19, x: 29},
  5: {y: 28, x: 43},
  6: {y: 57, x: 87},
  7: {y: 143, x: 219},
  8: {y: 204, x: 313},
  9: {y: 358, x: 548},
  10: {y: 717, x: 1096},
  11: {y: 1912, x: 2923},
};

const RES = [
  {
    // 0
    resolution: 352000.30264285713201388717 * 0.00028,
    origin: [455642.960778, 5478004.782551],
  },
  {
    // 1
    resolution: 281600.24210714286891743541 * 0.00028,
    origin: [455642.960778, 5488097.335224],
  },
  {
    // 2
    resolution: 211200.18157142860582098365 * 0.00028,
    origin: [455642.960778, 5478004.78254],
  },
  {
    // 3
    resolution: 140800.12103571428451687098 * 0.00028,
    origin: [455642.960778, 5478004.782528],
  },
  {
    // 4
    resolution: 105600.09078571430291049182 * 0.00028,
    origin: [455642.960778, 5478004.78254],
  },
  {
    // 5
    resolution: 70400.06053571429220028222 * 0.00028,
    origin: [455642.960778, 5472958.506227],
  },
  {
    // 6
    resolution: 35200.03026428571320138872 * 0.00028,
    origin: [455642.960778, 5472958.506212],
  },
  {
    // 7
    resolution: 14080.01210357142917928286 * 0.00028,
    origin: [455642.960778, 5471949.250922],
  },
  {
    // 8
    resolution: 9856.00847500000054424163 * 0.00028,
    origin: [455642.960778, 5471444.623325],
  },
  {
    // 9
    resolution: 5632.00484285714355792152 * 0.00028,
    origin: [455642.960778, 5471545.548852],
  },
  {
    // 10
    resolution: 2816.00242142857177896076 * 0.00028,
    origin: [455642.960778, 5471545.548852],
  },
  {
    // 11
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 12
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 13
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 14
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 15
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 16
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 17
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
  {
    // 18
    resolution: 1056.00090714285715876031 * 0.00028,
    origin: [455642.960778, 5471419.391821],
  },
];

const TOOLTIP_MAX_LENGTH = 75;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit, OnDestroy {
  private filteredData: Event[] = [];
  private fulldayData: Event[];
  private mapSubscription: Subscription;
  private cityMap: L.Map;
  subject$ = new Subject();

  // ajoute des marqeurs pour les faits/evts;
  private markersChecked = true;
  private clusterEvts: LC.MarkerClusterGroup;

  // occupation
  //private occupationLayers: L.LayerGroup;
  private clusterOcc: LC.MarkerClusterGroup;



  // Emprises
  private empriseLayers: L.LayerGroup;
  private empriseLayersChecked = true;

  // heatmap
  private heatmapLayer: HeatmapOverlay;
  private heatmapLayerChecked = true;

  private control = new L.Control.Layers();
  private categoryUtil = new CategoryUtil();
  private heatmapConf = new HEATMAPConf();

  private markerClicked: L.Marker;
  private evtClicked: Event;

  private dateImpMax: Date;

  private isFirst = true;
  private isInit = true;
  private wmtsCRS;
  private mapNB: L.TileLayer;
  private mapSatellite: L.TileLayer;
  private isMapWMTS: boolean;
  private suiviTimer;

  private filterUser = new Filter();
  deviceMobile:boolean = false;

  @ViewChild(LegendComponent, {static: true})
  private legend: LegendComponent;
  private destroyed$: Subject<void> = new Subject<void>();

  sidnavCanOpen: boolean = false;
  @Output() sidenavchange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private controllerService: ControllerService,
    private sigService: SigService,
    private filterService: FilterService,
    private appConfigService: AppConfigService,
    private baseParams: OBDTPMDefs,
    private mobileStateService: MobileStateService,
    private renderer: Renderer2, private el: ElementRef
  ) {
    this.dataService.initDayData();
  }


  ngOnInit() {
    this.mobileStateService.subscribeToLayoutChanges().subscribe(observerResponse => {

      this.deviceMobile = this.mobileStateService.isMobile();

    });

    this.baseParams.MAP_DEF.forEach(value => {
      if (value.is_default) {
        this.isMapWMTS = value.type === 'WMTS';
      }
    });

    this.wmtsCRS = new proj4.CRS(
      'EPSG:3857',
      '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0' +
      '+units=m +nadgrids=@null +wktext  +no_defs',
      {resolutions: RES},
    );

    this.initMap();
    // écoute l'arrivée de la données événements;
    this.mapSubscription = this.dataService.filteredDataSubject.subscribe(filterdData => {
      this.filteredData = filterdData;
      this.clearMap();
      this.showDataInMap(this.filteredData);
      this.heatmapLayer = this.isMapWMTS ? this.showHeatMap(this.filteredData, true) : this.showHeatMap(this.filteredData, false);
      this.updateMap();
    });

    // écoute le données sans filtrées;
    this.mapSubscription.add(this.dataService.fullDayDataSubject.subscribe(fulldayEvts => this.fulldayData = fulldayEvts));

    // écoute le filtre;
    this.mapSubscription.add(this.filterService.filterSubject.subscribe(filter => {
      this.filterUser = filter;

    }));

    if (this.cityMap) {
      // Extend leaflet control;

      if(!this.deviceMobile) {
        this.addCustomCtlToMap();
      }
    }

    // écoute l'evt cliqué dans la liste, une fois détecté, établie un marker sur la carte;
    this.mapSubscription.add(this.controllerService.evtSubjectSrcList.subscribe(evt => {
        if (this.markerClicked && this.cityMap.hasLayer(this.markerClicked)) {
          this.cityMap.removeLayer(this.markerClicked);
        }
        this.buildMarker(evt);
        this.controllerService.emitEvtDetailState(true);
      }),
    );
     this.mapSubscription.add(this.controllerService.evtSubjectSrcMap.subscribe(x => {
        this.markerClicked
     }),
     );
    // écoute la fermeture de l'évènement détail;
    this.mapSubscription.add(this.controllerService.evtDetailCloseSubject.subscribe(isOpen => {
        if (!isOpen) {
          if (this.cityMap.hasLayer(this.markerClicked)) {
            this.cityMap.removeLayer(this.markerClicked);
          }
          this.markerClicked = undefined;
        }
      }),
    );

    // center the map when only one sector is selected;
    this.mapSubscription.add(this.controllerService.centerMapSubject.subscribe(latlng => {
        this.cityMap.setView(latlng, this.cityMap.getZoom());
      }),
    );

    if (this.cityMap) {
      this.cityMap.on('baselayerchange', (e) => {
        const presentZoom = this.cityMap.getZoom();
        const presentCenter = this.cityMap.getCenter();
        if (this.cityMap.hasLayer(this.mapNB) || this.cityMap.hasLayer(this.mapSatellite)) {
          // clear the cluster,creating marker and adding to it once again;
          this.clearCluster();
          if (this.filteredData) {
            this.filteredData.forEach(data => {
              if (!isNaN(data.latitude) && !isNaN(data.longitude) && data.nom && data.categorie) {
                this.defineMarker(data);
              }
            });
          }

          delete this.cityMap.options.crs;
          this.cityMap.options.crs = this.wmtsCRS;
          this.heatmapLayer = this.showHeatMap(this.filteredData, true);
          this.updateMap();
          if (presentZoom >= 10) {
            this.cityMap.setView(presentCenter, presentZoom - 10);
          } else {
            this.cityMap.setView(presentCenter, presentZoom);
          }
          this.isMapWMTS = true;
        } else {
          // clear the cluster,then creating marker and adding to it once again;
          this.clearCluster();
          if (this.filteredData) {
            this.filteredData.forEach(data => {
              if (!isNaN(data.latitude) && !isNaN(data.longitude) && data.nom && data.categorie) {
                this.defineMarker(data);
              }
            });
          }

          delete this.cityMap.options.crs;
          this.cityMap.options.crs = L.CRS.EPSG3857;
          this.heatmapLayer = this.showHeatMap(this.filteredData, false);
          this.updateMap();
          if (presentZoom <= 8 && this.isMapWMTS) {
            this.cityMap.setView(presentCenter, presentZoom + 10);
          } else {
            this.cityMap.setView(presentCenter, presentZoom);
          }
          this.isMapWMTS = false;

          if (this.cityMap.options.maxBounds) {
            delete this.cityMap.options.maxBounds;
          }
        }
      });
    }
    // this.cityMap.on('zoomend', () => {
    //   console.log('zoom in map:', this.cityMap.getZoom());
    //   // console.log('bounds:'+JSON.stringify(this.cityMap.getBounds()));
    // });
  }


  // incorporation of external module pattern: by Object interface;
  addCustomTileLayerToMap(url: string, options: any, tileLimitArr: TileLimit) {
    // var retina = (window.devicePixelRatio || (window.screen.deviceXDPI / window.screen.logicalXDPI)) > 1;
    const customTileLayer = (L.TileLayer as any).extend({
      tileLimitArr: {},
      defaultWMTSParams: {
        service: 'WMTS',
        format: 'image/png',
        transparent: false,
        version: '1.1.1',
      },
      options: {
        crs: null,
        uppercase: false,
      },

      initialize(curl: string, customOptions: any, customTileLimitArr: TileLimit) {
        this._url = curl;
        this.tileLimitArr = customTileLimitArr;
        const wmtsParams = L.Util.extend({}, this.defaultWMTSParams); // no special options for this implementation;
        // all keys that are not TileLayer options go to wmts params;
        for (const i in customOptions) {
          if (!(i in this.options)) {
            wmtsParams[i] = customOptions[i];
          }
        }
        customOptions = L.Util.setOptions(this, customOptions);
        this.wmsParams = wmtsParams;
      },

      onAdd(map) {
        L.TileLayer.prototype.onAdd.call(this, map);
      },

      getTileUrl(coords) {
        const data = {
          // r: Browser.retina ? '@2x' : '',
          r: '',
          s: this._getSubdomain(coords),
          x: coords.x,
          y: coords.y,
          z: this._getZoomForUrl(),
        };
        if (this._map && !this._map.options.crs.infinite) {
          const invertedY = this._globalTileRange.max.y - coords.y;
          if (this.options.tms) {
            data.y = invertedY;
          }
          data['-y'] = invertedY;
        }
        return data.z < 12 &&
        data.x >= 0 &&
        data.y >= 0 &&
        this.tileLimitArr[data.z].x - data.x >= 0 &&
        this.tileLimitArr[data.z].y - data.y >= 0
          ? L.Util.template(this._url, L.Util.extend(data, this.options))
          : 'assets/img/empty_tile.svg';
      },
    });
    return new customTileLayer(url, options, tileLimitArr);
  }

  addCustomCtlToMap() {
    const customControl = L.Control.extend({
      options: {
        position: 'bottomright',
      },

      onAdd: (map) => {
        const controllerContainer = document.createElement('div');
        const controllerContainerFirstLine = document.createElement('div');
        const controllerContainerSecondLine = document.createElement('div');
        const controllerContainerThirdLine = document.createElement('div');

        // créer marqueur (seulement pour evts)
        const markerNode = document.createElement('input');
        const markerLabel = document.createElement('label');
        markerNode.setAttribute('type', 'checkbox');
        markerNode.id = 'marker_id';
        markerNode.checked = this.markersChecked;
        markerLabel.htmlFor = 'marker_id';
        markerLabel.appendChild(document.createTextNode(LAYERS_NAMES.Marker));
        controllerContainerFirstLine.appendChild(markerNode);
        controllerContainerFirstLine.appendChild(markerLabel);

        // créer heatmap
        const heatmapNode = document.createElement('input');
        const heatmapLabel = document.createElement('label');
        heatmapNode.setAttribute('type', 'checkbox');
        // heatmapNode.checked = this.heatmapChecked;
        heatmapNode.id = 'heatmap_id';
        heatmapLabel.htmlFor = 'heatmap_id';
        heatmapNode.checked = this.heatmapLayerChecked;
        heatmapLabel.appendChild(document.createTextNode(LAYERS_NAMES.HeatMap));
        controllerContainerFirstLine.appendChild(heatmapNode);
        controllerContainerFirstLine.appendChild(heatmapLabel);

        // créer emprise
        const empriseNode = document.createElement('input');
        const empriseLabel = document.createElement('label');
        empriseNode.setAttribute('type', 'checkbox');
        empriseNode.checked = this.empriseLayersChecked;
        empriseNode.id = 'emprise_id';
        empriseLabel.htmlFor = 'emprise_id';
        empriseLabel.appendChild(document.createTextNode(LAYERS_NAMES.Emprise));
        controllerContainerSecondLine.appendChild(empriseNode);
        controllerContainerSecondLine.appendChild(empriseLabel);

        controllerContainer.appendChild(controllerContainerFirstLine);
        controllerContainer.appendChild(controllerContainerSecondLine);
        controllerContainer.appendChild(controllerContainerThirdLine);

        // ajoute des styles;
        controllerContainer.style.backgroundColor = 'white';
        controllerContainer.style.right = '-48px';
        controllerContainer.style.padding = MAP_CONTROL_STYLE.PADDING;

        markerLabel.style[MAP_CONTROL_STYLE.MARGIN_LEFT_TXT] = MAP_CONTROL_STYLE.MARGIN_LEFT;
        markerLabel.style.width = MAP_CONTROL_STYLE.WIDTH_L;

        heatmapNode.style[MAP_CONTROL_STYLE.MARGIN_LEFT_TXT] = MAP_CONTROL_STYLE.MARGIN_LEFT;
        heatmapLabel.style[MAP_CONTROL_STYLE.MARGIN_LEFT_TXT] = MAP_CONTROL_STYLE.MARGIN_LEFT;
        heatmapLabel.style.width = MAP_CONTROL_STYLE.WIDTH_S;

        empriseNode.style[MAP_CONTROL_STYLE.MARGIN_RIGHT_TXT] = MAP_CONTROL_STYLE.MARGIN_LEFT;
        empriseLabel.style[MAP_CONTROL_STYLE.MARGIN_RIGHT_TXT] = MAP_CONTROL_STYLE.MARGIN_LEFT;

        // câbler avec les couches(layers);
        L.DomEvent.on(markerNode, 'click', () => {
          if (this.markersChecked) {
            this.cityMap.removeLayer(this.clusterEvts);
            this.cityMap.removeLayer(this.clusterOcc);
            if (this.markerClicked && this.cityMap.hasLayer(this.markerClicked)) {
              this.cityMap.removeLayer(this.markerClicked);
            }
            // this.cityMap.removeLayer(this.occupationLayers);
          } else {
            this.cityMap.addLayer(this.clusterEvts);
            this.cityMap.addLayer(this.clusterOcc);
            if (this.markerClicked && !this.cityMap.hasLayer(this.markerClicked)) {
              this.cityMap.addLayer(this.markerClicked);
            }
            //this.cityMap.addLayer(this.occupationLayers);
          }
          this.markersChecked = !this.markersChecked;
        });
        // heatmap
        L.DomEvent.on(heatmapNode, 'click', () => {
          this.heatmapLayerChecked
            ? this.cityMap.removeLayer(this.heatmapLayer)
            : this.cityMap.addLayer(this.heatmapLayer);
          this.heatmapLayerChecked = !this.heatmapLayerChecked;
        });
        // emprise
        L.DomEvent.on(empriseNode, 'click', () => {
          this.empriseLayersChecked
            ? this.cityMap.removeLayer(this.empriseLayers)
            : this.cityMap.addLayer(this.empriseLayers);
          this.empriseLayersChecked = !this.empriseLayersChecked;
        });

        // disable double click panel, map zoom in effect;
        controllerContainer.addEventListener('mouseenter', (e) => this.cityMap.doubleClickZoom.disable());
        controllerContainer.addEventListener('mouseleave', (e) => this.cityMap.doubleClickZoom.enable());

        return controllerContainer;
      },

      onRemove: (map) => {
        // TODO;
      },
    });

    this.cityMap.addControl(new customControl());

    // TODO:retire ce contrôle quelque part lorsqu'on n'en a plus besoin;
    // this.cityMap.removeControl(ce_control);
  }

  showHeatMap(evts: Event[], isWMTS: boolean) {
    let hm: HeatmapOverlay;
    try {
      if (this.cityMap.hasLayer(this.heatmapLayer)) {
        this.cityMap.removeLayer(this.heatmapLayer);
      }
      const dataPoints: HeatPoint[] = [];
      const evtsArr = this.categoryUtil.getCategoryEvts();
      evts
        .filter((evt) => evtsArr.includes(evt.categorie))
        .filter((evt) => !isNaN(evt.latitude) || !isNaN(evt.longitude))
        .forEach((evt) => {
          dataPoints.push(new HeatPoint(evt.latitude, evt.longitude, 10));
        });

      const heatData = {
        min: this.heatmapConf.HEATMAP_DATA_MIN,
        max: this.heatmapConf.HEATMAP_DATA_MAX,
        data: dataPoints,
      };

      const colors = this.heatmapConf.HEATMAP_COLOR.map((obj) => obj.color);
      const gradiants = this.heatmapConf.HEATMAP_COLOR.map((obj) => obj.gradiant);
      const evtRadius = this.heatmapConf.HEATMAP_RADIUS;
      const opacity = this.heatmapConf.HEATMAP_OPACITY;
      const evtBlur = this.heatmapConf.HEATMAP_BLUR;
      const gradiantObj = this.buildGradient(gradiants, colors);

      const configObject = {
        // radius,
        radius: evtRadius,
        maxOpacity: opacity,
        scaleRadius: true, // scales the radius based on map zoom level;
        useLocalExtrema: false, // if activated: uses the data maximum within the current map boundaries.
        latField: 'lat',
        lngField: 'lng',
        valueField: 'value',
        blur: evtBlur, // applied to all datapoints, the higher the blur factor is, the smoother the gradients will be;
        gradient: gradiantObj,
        isWMTS,
      };
      hm = new HeatmapOverlay(configObject);
      hm.setData(heatData);
    } catch (error) {
      console.log('Erreur de la création de HeatMap : ' + error);
    }
    return hm;
  }

  buildGradient(keys: string[], values: string[]) {
    const gradients = {};
    for (let i = 0; i < keys.length; i++) {
      gradients[keys[i]] = values[i];
    }
    return gradients;
  }

  initMap() {
    // Init map;
    this.baseParams.MAP_DEF.forEach((value) => {
      if (value.is_default) {
        if (value.type === 'WMTS') {
          this.cityMap = L.map('cityMap', {layers: [], crs: this.wmtsCRS}).setView(
            this.baseParams.MAP_CTRE,
            this.baseParams.MAP_ZOOM - 7,
          );
        } else {
          this.cityMap = L.map('cityMap', {layers: []}).setView(this.baseParams.MAP_CTRE, this.baseParams.MAP_ZOOM);
        }
      }
      if(this.deviceMobile){
        this.cityMap.zoomControl.remove();
      }
    });

    ///////////////////////////////////////////////////////////////////////////////////////////
    // Carte fond plan
    ///////////////////////////////////////////////////////////////////////////////////////////
    this.baseParams.MAP_DEF.forEach((mapDefinition) => {
      if (mapDefinition.showIn.includes(this.appConfigService.getAppConfig().env)) {
        let mapTemp: L.Layer;
        if (mapDefinition.type !== 'WMTS') {
          mapTemp = L.tileLayer(mapDefinition.url, mapDefinition.props);
        } else {
          if (mapDefinition.label === 'à définir') {
            this.mapNB = this.addCustomTileLayerToMap(mapDefinition.url, mapDefinition.props, LOADING_LIMIT_WMTS);
            mapTemp = this.mapNB;
          }
        }
        if (mapDefinition.is_default === true) {
          mapTemp.addTo(this.cityMap);
        }
        this.control.addBaseLayer(mapTemp, '<span class="map-name">' + mapDefinition.label + '</span>');
      }
    });

    if (this.cityMap) {
      // init panes pour contrôler l'ordre des layers-> bottom first; exemple:https://jsfiddle.net/3v7hd2vx/51/
      this.cityMap.createPane(L_CLUSTER.PaneEvts).style.zIndex = '590';
      this.cityMap.createPane(L_CLUSTER.PaneOcc).style.zIndex = '590';
      this.cityMap.createPane(LAYERS_NAMES.HeatMap).style.zIndex = '450';
      this.cityMap.createPane(LAYERS_NAMES.Emprise).style.zIndex = '420';
      this.cityMap.createPane(LAYERS_NAMES.Occupation).style.zIndex = '520';

      // this.occupationLayers = new L.LayerGroup(undefined, {pane: LAYERS_NAMES.Occupation});
      this.empriseLayers = new L.LayerGroup(undefined, {pane: LAYERS_NAMES.Emprise});
      this.heatmapLayer = new L.LayerGroup(undefined, {pane: LAYERS_NAMES.HeatMap});

      this.instanciateCluster();
    }
  }

  instanciateCluster() {
    this.clearCluster();
    this.clusterEvts = new LC.MarkerClusterGroup({
      maxClusterRadius: CLUSTER_RADIUS,
      clusterPane: L_CLUSTER.PaneEvts,
      iconCreateFunction: cluster => this.defineClusterIcon(cluster),
      animate: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
    });

    this.clusterEvts.on('clusterclick', cluster => {
      cluster.layer.spiderfy();
    });

    this.clusterOcc = new LC.MarkerClusterGroup({
      maxClusterRadius: CLUSTER_RADIUS,
      clusterPane: L_CLUSTER.PaneOcc,
      iconCreateFunction: cluster => this.defineClusterIcon(cluster),
      animate: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
    });

    this.clusterOcc.on('clusterclick', cluster => {
      cluster.layer.spiderfy();
    });
  }

  defineClusterIcon(cluster: LC.MarkerClusterGroup) {
    const rmax = 22; // max cluster radius
    const children = cluster.getAllChildMarkers();
    const nbr = children.length; // number of markers in cluster;
    const strokeWidth = 1; //
    const r = rmax - 2 * strokeWidth - (nbr < 10 ? 8 : nbr < 100 ? 6 : 5);
    const iconDim = (r + strokeWidth) * 2;
    const data = d3.nest()
      .key(d => d.options.alt)
      .entries(children, d3.map);
    const html = this.stylePie({
      data,
      valueFunc: d => d.values.length,
      strokeWidth: 1,
      outerRadius: r,
      innerRadius: r - 3,
      pieLabel: nbr,
      // pathTitleFunc: d => d.data.key+': '+d.data.values.length
    });

    return new L.DivIcon({
      html,
      className: 'marker-cluster',
      iconSize: new L.Point(iconDim, iconDim),
    });
  }

  private stylePie(options) {
    if (!options.data || !options.valueFunc) {
      return '';
    }
    const data = options.data;
    const valueFunc = options.valueFunc;
    const rOuter = options.outerRadius ? options.outerRadius : 25; // default outer radius = 25 px;
    const rInner = options.innerRadius ? options.innerRadius : rOuter - 10;
    const strokeWidth = options.strokeWidth ? options.strokeWidth : 1;
    const pieLabel = options.pieLabel ? options.pieLabel : d3.sum(data, valueFunc);
    const centerCoord = rOuter + strokeWidth;
    const widthSVG = centerCoord * 2;
    const heightSVG = widthSVG;
    const categories = data.map(cat => cat.key);
    let colors = [];

    // Check if normal events categories
    const normalEventsCat = this.categoryUtil.getCategoriesColor(categories).some(el => el !== null);

    colors = this.categoryUtil.getCategoriesColor(categories);

    const clusterColors = d3.scaleOrdinal().range(colors);
    // pie;
    const donut = d3.pie();
    const arc = d3.arc().innerRadius(rInner).outerRadius(rOuter);
    // svg
    const clusterSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    // pie chart;
    const pieChart = d3
      .select(clusterSVG)
      .data([data]) // Binds the specified array of data with the selected elements
      .attr('width', widthSVG)
      .attr('height', heightSVG)
      .attr('stroke', '#606060');

    const arcs = pieChart
      .selectAll('g.arc')
      .data(donut.value(valueFunc))
      .enter()
      .append('svg:g') // appends a new element of this type (tag name) before the next following sibling in the update selection;
      .attr('class', 'arc')
      .attr('transform', 'translate(' + centerCoord + ',' + centerCoord + ')');

    arcs
      .append('svg:path')
      .attr('stroke-width', strokeWidth)
      .attr('d', arc)
      .style('fill', d => clusterColors(d.data.key));

    pieChart
      .append('svg:circle')
      .attr('cx', centerCoord)
      .attr('cy', centerCoord)
      .attr('r', rInner)
      .attr('fill', 'white');

    pieChart
      .append('text')
      .attr('x', centerCoord)
      .attr('y', centerCoord)
      .attr('text-anchor', 'middle')
      .attr('dy', '.3em')
      .attr('fill', '#212121')
      .attr('stroke', '#000000')
      .attr('font-weight', 'lighter')
      .text(pieLabel);

    return this.serialiezXmlNode(clusterSVG);
  }

  serialiezXmlNode(xmlNode) {
    if (typeof (window as any).XMLSerializer !== 'undefined') {
      return new (window as any).XMLSerializer().serializeToString(xmlNode);
    } else if (typeof xmlNode.xml !== 'undefined') {
      return xmlNode.xml;
    }
    return '';
  }

  filter(el: Event): boolean {
    let res: boolean;
    this.filterService.filterSubject.subscribe(filter => res = (
      new Date(el.horodateDebut).getTime() <= new Date(filter.toDate).getTime() && new Date(filter.fromDate).getTime() < new Date(el.horodateFin).getTime()
      && filter.secteurs.includes(el.secteur)
      && filter.categories.includes(el.categorie))
    );
    return res;
  }


  showDataInMap(data: Event[]) {
    if (this.evtClicked) {
      if (this.filter(this.evtClicked)) {
        if (this.markerClicked && !this.cityMap.hasLayer(this.markerClicked)) {
          this.cityMap.addLayer(this.markerClicked);
        }
      } else {
        if (this.markerClicked && this.cityMap.hasLayer(this.markerClicked)) {
          this.cityMap.removeLayer(this.markerClicked);
        }
      }
    }
    data.forEach(evt => {
      if (!isNaN(evt.latitude) && !isNaN(evt.longitude) && evt.nom && evt.categorie) {
        evt.setLaneClosureStatus();
        // Cas d'adresses non-géocodées
        const badgeColor = this.categoryUtil.getCategoryBDColor(evt.categorie);
        const fontColor = this.categoryUtil.getCategoryFontColor(evt.categorie);
        let strShortNom = evt.nom;
        if (strShortNom.length > TOOLTIP_MAX_LENGTH) {
          strShortNom = strShortNom.substring(0, 72) + '...';
        }
        const tooltipStr = `
                <span class="badge rectangle" style="background-color:${badgeColor};color:${fontColor}; padding:5px;">${evt.categorie}</span>
                <p class="titre-tooltip">${strShortNom}</p>
                `;

                const emprisePopup = `<div style="text-align: center">
              <span class="badge rectangle" style="background-color:${badgeColor};color:${fontColor}; font-size:1.2em"; padding:5px;">${evt.categorie}</span>
              <p class="titre-tooltip" style="font-size: 18px; text-align: center">${strShortNom}</p>
              </div>`;
          const div = document.createElement('div');
          div.className ="popup-container";
          div.innerHTML = emprisePopup;
          //const popupContent = emprisePopup;
          const buton = document.createElement('button');
          buton.id = 'buton';
          buton.innerHTML = " Plus d'informations";

        buton.className ="btn-principal-mode-mobile btp-popup mat-raised-button mat-button-base";

          div.appendChild(buton);

        const evtLatlng = L.latLng(evt.latitude, evt.longitude);
        const impactRadius = this.categoryUtil.getCategoryRadius(evt.categorie, evt.nom);

        ///////////////////////////////////////////////////////////////////////////////////////////
        // EMPRISE add emprise
        ///////////////////////////////////////////////////////////////////////////////////////////
        if (evt.emprise !== null && evt.emprise !== '') {
          // création
          try {
            let laneClosureDisplay = false;
            if ((this.dataService.filter.categories.includes(GROUP.FERMETURES_VOIES) ||
                  this.dataService.filter.categories.includes('Fermeture complète') ||
                  this.dataService.filter.categories.includes(GROUP.FERMETURES)) && evt.laneClosureStatus === 'E_CLOSE_LANE') {
              laneClosureDisplay = true;
            }
            if ((this.dataService.filter.categories.includes(GROUP.FERMETURES_VOIES) ||
                  this.dataService.filter.categories.includes('Fermeture partielle') ||
                  this.dataService.filter.categories.includes(GROUP.FERMETURES)) && evt.laneClosureStatus === 'E_HALF_CLOSE_LANE') {
              laneClosureDisplay = true;
            }
            const dataStr = JSON.stringify(evt.emprise);
            const geoJSONData = JSON.parse(dataStr);
            const emprise = L.geoJSON(geoJSONData, {
              style: (evt.nom.includes('Manifestations') && !laneClosureDisplay) ?
                this.categoryUtil.getStyleEmprise(laneClosureDisplay, 'E_MANIF', evt.categorie) :
                this.categoryUtil.getStyleEmprise(laneClosureDisplay, evt.laneClosureStatus, evt.categorie)
            });

            emprise.addTo(this.empriseLayers);
           /* // activation du hover => tooltip
            emprise.bindTooltip(tooltipStr);
            // activation du clic => détail événement
            this.activateEvtDetail(evt, emprise, this.empriseLayers);*/
            if(this.deviceMobile){

               //L.DomEvent.on(div, 'click', () => {document.getElementById('buton').onclick = this.myMethode});
             L.DomEvent.on(buton, 'click', () => {
                this.sidnavCanOpen = true;
                this.sidenavchange.emit(this.sidnavCanOpen); // permet d'envoyer l'information au sidbar

                this.activateEvtDetail(evt, emprise, this.clusterEvts);
                emprise.closePopup();
            });
              emprise.bindPopup(div).openPopup();
              this.activateEvtDetail(evt, emprise, this.clusterEvts);

/*
              L.DomEvent.on(crossbutton, 'click', () => {
                emprise.closePopup();
              });*/

            }
            else{
              // activation du hover => tooltip
              emprise.bindTooltip(tooltipStr);
              // activation du clic => détail événement
              this.activateEvtDetail(evt, emprise, this.empriseLayers);
            }
          } catch {
            console.log('erreur de JSON.parse evt avec idSource:' + evt.idSource);
          }
        }

        ///////////////////////////////////////////////////////////////////////////////////////////
        // Add marker avec pastille ou sans pastille selon ses note de risques;
        ///////////////////////////////////////////////////////////////////////////////////////////
        // création
        this.defineMarker(evt);
        if (evt.flagType && evt.flagType === 'F') {
          if (this.isInit) {
            this.dateImpMax = new Date(evt.horodateImp);
            this.isInit = false;
          }
          const evtDateImp = new Date(evt.horodateImp);
          if (this.dateImpMax < evtDateImp) {
            this.dateImpMax = evtDateImp;
          }
        }
      }
    }); // fin data.forEach()

    if (!(data.length === 0)) {
      if (!this.dateImpMax) {
        // toujours pas de fait, initialisez la pour aujourd'hui à 0 heure;
        this.dateImpMax = new Date();
        this.dateImpMax.setHours(0);
      }
      if (this.isFirst) {
        this.dataService.dateImpMax = this.dateImpMax;
        this.isFirst = false;
      }
    }
    // this.control.addTo(this.cityMap);
  }

  private defineMarker(evt: Event) {
    const badgeColor = this.categoryUtil.getCategoryBDColor(evt.categorie);
    const fontColor = this.categoryUtil.getCategoryFontColor(evt.categorie);
    const provEvt = evt.statut === 'EVT_A_VALIDER';
    let strShortNom = evt.nom;
    if (strShortNom.length > 75) {
      strShortNom = strShortNom.substring(0, 72) + '...';
    }

    const mainBadge = `background-color:${badgeColor}; color:${fontColor}; padding: 5px;`;
    const tooltipStr =
      `<span class="m-badge rectangle" style="${mainBadge}">${evt.categorie}</span>
      ${provEvt
        ? `<span class="m-badge m-badge--provisional rectangle">${BADGE_CONF.PROVISIONAL_BADGE.TEXT}</span>`
        : ''}
      <p class="titre-tooltip">${strShortNom}</p>`
    ;


    const iconFactor = this.categoryUtil.getCategoryIconSize(evt.categorie);
    const iconWidth = MARKER_CONF.ICON_INIT_SIZE * iconFactor;
    const iconHeight = iconWidth * MARKER_CONF.ICON_DEFAULT_RATIO;

    let marker: L.Marker = null;
    if (evt.categorie !== 'Travaux (permis de construire)') {
      const markerIconUrl =
        evt.noteRisque > 3
          ? provEvt ? this.categoryUtil.getCategoryProvisionalMarkerUrl(evt.categorie) : this.categoryUtil.getCategoryMarkerUrl(evt.categorie)
          : provEvt ? this.categoryUtil.getCategoryProvisionalMarkerUrl(evt.categorie) : this.categoryUtil.getCategoryMarkerUrl(evt.categorie)
      ;
      if (markerIconUrl != null) {
        // sauf travaux donc iconUrl = null;
        const iconNature = new L.Icon({
          iconUrl: markerIconUrl,
          iconAnchor: [iconWidth / 2, iconHeight],
          iconSize: [iconWidth, iconHeight],
        });
        marker = L.marker(L.latLng(evt.latitude, evt.longitude), {
          draggable: false,
          icon: iconNature,
          alt: evt.categorie
        });
        const div = document.createElement('div');
        div.className ="popup-container";
        const popup =`
    <div>
      <span class="m-badge rectangle" style="${mainBadge} font-size:1.2em"><img width="30px" height="30x" src="${markerIconUrl}"></img>${evt.categorie}</span>
        ${provEvt ? `<div class="m-badge m-badge--provisional rectangle">${BADGE_CONF.PROVISIONAL_BADGE.TEXT}</div>` : ''}

      <p class="titre-tooltip" style="font-size: 1.1em; text-align: center" >${strShortNom}</p>
      </div>`
     ;
        div.innerHTML = popup;
        const buton = document.createElement('button');
        buton.id = 'buton';
        buton.innerHTML = "Plus d'informations";
        buton.className ="btn-principal-mode-mobile btp-popup mat-raised-button mat-button-base";

        div.appendChild(buton);
        //if mobile
        if(this.deviceMobile) {


          L.DomEvent.on(buton, 'click', () => {
            this.sidnavCanOpen = true;
            this.sidenavchange.emit(this.sidnavCanOpen); // permet d'envoyer l'information au sidbar

            this.activateEvtDetail(evt, marker, this.clusterEvts);
            marker.closePopup();
          });

          /*L.DomEvent.on(crossbutton, 'click', () => {
            marker.closePopup();
          });*/
          //marker.addEventListener('click', ()=>{marker.bindPopup(div)});
          marker.bindPopup(div).openPopup();

          //L.DomEvent.on(document.getElementById('buton'), 'click', ()=>{console.log('test')})
          this.activateEvtDetail(evt, marker, this.clusterEvts);

        } else {
          marker.bindTooltip(tooltipStr);
          this.activateEvtDetail(evt, marker, this.clusterEvts);
        }
      }

      if (evt.flagType === 'E' || evt.flagType === 'U') {
        if (evt.idFlux === 'id30') {
          if (this.dataService.filter.categories.includes('Travaux') && evt.nom.includes('Travaux') ||
                this.dataService.filter.categories.includes('Divers') && evt.nom.includes('Manifestation')) {
            marker.addTo(this.clusterEvts);
          }
        } else {
          marker.addTo(this.clusterEvts);
        }
      } else if (evt.flagType === 'OCC') {
        marker.addTo(this.clusterOcc);
        // this.occupationLayers.addLayer(marker);
      }
    }
  }

  private updateMap() {
    this.markersChecked
      ? this.cityMap.addLayer(this.clusterEvts)
      : this.cityMap.removeLayer(this.clusterEvts);
    this.empriseLayersChecked
      ? this.cityMap.addLayer(this.empriseLayers)
      : this.cityMap.removeLayer(this.empriseLayers);
    this.heatmapLayerChecked
      ? this.cityMap.addLayer(this.heatmapLayer)
      : this.cityMap.removeLayer(this.heatmapLayer);
    // this.markersChecked
    //   ? this.cityMap.addLayer(this.occupationLayers)
    //   : this.cityMap.removeLayer(this.occupationLayers);
    this.markersChecked
      ? this.cityMap.addLayer(this.clusterOcc)
      : this.cityMap.removeLayer(this.clusterOcc);
  }

  private clearMap() {
    this.cityMap.removeLayer(this.clusterEvts);
    this.cityMap.removeLayer(this.heatmapLayer);
    this.cityMap.removeLayer(this.empriseLayers);
    this.cityMap.removeLayer(this.clusterOcc);
    // if (this.cityMap.hasLayer(this.occupationLayers)) {
    //   this.cityMap.removeLayer(this.occupationLayers);
    // }

    // LayerGroup.clearLayers(): removes all the layers from the group;
    this.empriseLayers.clearLayers();
    this.clusterEvts.clearLayers();
    this.clusterOcc.clearLayers();

    // if (this.occupationLayers) {
    //   this.occupationLayers.clearLayers();
    // }
  }


  private activateEvtDetail(evt: Event, layer: L.Layer, layerGroup: L.LayerGroup) {
    const idLayer = layerGroup.getLayerId(layer);
    this.controllerService.setMap(idLayer, evt);
    layer.on('click', e => {
      this.controllerService.setEventClicked(idLayer);
      this.buildMarker(evt);
    });
  }


  private clearCluster() {
    if (this.cityMap.hasLayer(this.clusterEvts)) {
      this.cityMap.removeLayer(this.clusterEvts);
      this.clusterEvts.clearLayers();
    }
    if (this.cityMap.hasLayer(this.clusterOcc)) {
      this.cityMap.removeLayer(this.clusterOcc);
      this.clusterOcc.clearLayers();
    }
  }

  private centerItemAt(latlng: L.LatLng) {
    const latLngs = [latlng];
    const bounds = L.latLngBounds(latLngs);
    this.cityMap.fitBounds(bounds, {maxZoom: this.cityMap.getZoom()});
  }

  private buildMarker(evt: Event) {
    this.evtClicked = evt;
    // if it has already exsited, remove it;
    if (this.markerClicked && this.cityMap.hasLayer(this.markerClicked)) {
      this.cityMap.removeLayer(this.markerClicked);
    }
    if (this.filter(evt)) {
      const iconWidth = MARKER_CONF.ICON_INIT_SIZE * MARKER_CONF.ICON_CLICKED_GRANDIR_RATIO;
      const iconHeight = iconWidth * MARKER_CONF.ICON_DEFAULT_RATIO;
      const iconSize = L.point(iconWidth, iconHeight);
      const provEvt = evt.statut === 'EVT_A_VALIDER';
      const iconUrl =
        evt.noteRisque > 3 && evt.flagType === 'E'
          ? provEvt ? this.categoryUtil.getCategoryProvisionalMarkerHoverUrl(evt.categorie) : this.categoryUtil.getCategoryMarkerHoverUrl(evt.categorie)
          : provEvt ? this.categoryUtil.getCategoryProvisionalMarkerHoverUrl(evt.categorie) : this.categoryUtil.getCategoryMarkerHoverUrl(evt.categorie);

      if (iconUrl != null) {
        const myIcon = new L.Icon({
          iconUrl,
          iconAnchor: [iconWidth / 2, iconHeight],
          iconSize,
        });
        this.markerClicked = L.marker(new L.LatLng(evt.latitude, evt.longitude), {icon: myIcon});
        this.markerClicked.setZIndexOffset(1000001);
        if (this.markersChecked) {
          this.markerClicked.addTo(this.cityMap);
        }
        if (!this.cityMap.getBounds().contains(this.markerClicked.getLatLng())) {
          // if marker not in map, center it;
          this.centerItemAt(this.markerClicked.getLatLng());
        }
      }
    }
  }

  hoverOnIcon() {
    if(!this.deviceMobile) {
      this.controllerService.showLegendIcon = true;
      this.legend.isFocusOnIcon = true;
    }
  }
  showFiltre() {
    if(this.deviceMobile) {
      this.controllerService.emitFilterMobileState(true);
    }
  }

  hoverOffTable() {
    this.controllerService.showLegendIcon = false;
  }

  getLegendHeight() {
    const heightLegend = window.innerHeight - 270;
    if(!this.deviceMobile) {
      return this.legend.isShowTable() ? `${heightLegend}px` : `0px`;
    }
    else{
      return `0px`;
    }
  }

  ngOnDestroy() {
    this.dataService.cancelApiRequest();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.mapSubscription.unsubscribe();
    if (this.suiviTimer) {
      clearInterval(this.suiviTimer);
    }
  }

  formatTime(dateParam) {
    const ts = moment.utc(dateParam);
    return ts.local().format('HH:mm');
  }
}
