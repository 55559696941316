import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IndicateurService } from 'src/app/services/indicateur.service';
import { Subscription, Observable } from 'rxjs';
import { DpirUtil } from 'src/app/utils/dpir.util';
import { MatDialog } from '@angular/material';
import { URL } from '../../../app-routing.module';
import { Indice } from 'src/app/models/indice.model';
import { DateService } from 'src/app/services/date.service';
import {MobileStateService} from 'src/app/services/mobile-state.service';
@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.css'],
})
export class HeaderTopComponent implements OnInit, OnDestroy {

  headerTopClass='';
  logoClass='';

  deviceMobile:boolean = false;
  indiceJourneeSubscription: Subscription = new Subscription();
  // pour indice de la journée;
  dpirUtil = new DpirUtil();
  indiceJournee: number;
  dateChosen: Date = new Date();
  indice: Observable<Indice>;
  indiceRequalifie;

  controller = {
    map: true,
  };

  role = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private indicateurService: IndicateurService,
    public dialog: MatDialog,
    private dateService: DateService,
    private mobileStateService: MobileStateService
  ) {

  }

  ngOnInit() {
    this.mobileStateService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.headerTopClass ='header-top';
      this.logoClass = "logo-bar";
      this.deviceMobile = this.mobileStateService.isMobile();
      if(this.deviceMobile){
        this.headerTopClass ='header-top-mobile';
        this.logoClass = "logo-bar-mobile";
      }

    });
    this.route.url.subscribe((url) => {
      const urlstr = url[0].path;
      for (const key in this.controller) {
        if (this.controller.hasOwnProperty(key)) {
          this.controller[key] = key === urlstr;
        }
      }
    });
    this.indicateurService.loadIndice(this.dateService.date);
    this.indicateurService.indiceSubject.subscribe(updatedIndice => {
      this.indiceJournee = updatedIndice.value;
      this.dateChosen = updatedIndice.dateIndice;
      this.indiceRequalifie = updatedIndice.name;
    });
  }


  goMap() {
    this.router.navigate([URL.MAP]);
  }

  getTextBy() {
    if (this.indiceJournee) {
      return this.dpirUtil.getTextBy('global', this.indiceJournee); // une fois reçu la valeur, mettre à jour l'indice de la journée;
    }
    return 'indisponible'; // valeur par défault
  }

  getColorBy() {
    if (this.indiceJournee) {
      return this.dpirUtil.getColorBy(this.indiceJournee, 'global'); // une fois reçu la valeur, mettre à jour l'indice de la journée;
    }
    return 'gray'; // valeur par défault
  }

  ngOnDestroy() {
    this.indiceJourneeSubscription.unsubscribe();
    this.indicateurService.cancelApiRequest();
  }
}
