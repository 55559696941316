import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';

export const URL = {
  MAP: 'map',
  FILTER: 'filtres'
};

const routes: Routes = [
  { path: '', component: SplashScreenComponent, pathMatch: 'full' },
  { path: URL.MAP, component: SidebarComponent}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
