import { Directive, ElementRef, AfterViewInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Directive({
  selector: '[stickyTableHeader]'
})
export class StickyTableHeaderDirective implements AfterViewInit {

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    $(this.el.nativeElement).stickyTableHeaders({cacheHeaderHeight: true});
  }

}
