import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import { distinctUntilChanged, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MobileStateService {

  public mobileMode: boolean;
  constructor(private breakpointObserver: BreakpointObserver) { }

  isMobile():boolean {

    return ( this.breakpointObserver.isMatched( Breakpoints.TabletPortrait) || this.breakpointObserver.isMatched( Breakpoints.Handset));
 }

 setMobileMode() {

 this.mobileMode =this.breakpointObserver.isMatched( Breakpoints.TabletPortrait) || this.breakpointObserver.isMatched( Breakpoints.Handset);
}
 subscribeToLayoutChanges(): Observable<BreakpointState> {
  return this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.Medium, Breakpoints.Small, Breakpoints.TabletLandscape,Breakpoints.TabletPortrait, Breakpoints.XSmall])
    .pipe(tap(value => console.log(value)),distinctUntilChanged());
}




}
