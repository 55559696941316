/* ************************* Map Control *************************** */
export const MAP_CONTROL_STYLE = {
  WIDTH_L: '75px',
  WIDTH_S: '55px',
  PADDING: '13px',
  MARGIN_LEFT: '15px',
  MARGIN_RIGHT: '13px',
  MARGIN_LEFT_COVERAGE: '20px',
  MARGIN_LEFT_TXT: 'margin-left',
  MARGIN_RIGHT_TXT: 'margin-right',
  WHITE_SPACE: 'nowrap'
};


/* ************************* When choosing sector, centering map *************************** */

export const SECTEUR_CENTRE = {
  NORD:    {lat: 43.343545, lng: 5.376986}, // Stade Roger Couderc
  CENTRE:  {lat: 43.297870, lng: 5.374588}, // Jardin des Vestiges
  SUD:     {lat: 43.260345, lng: 5.449940}, // Val Toulouse
  LITTORAL: {lat: 43.317066, lng: 5.360219}
};
