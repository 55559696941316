// tag : perf sidebar
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChoiceKeeper } from 'src/app/models/choice.keeper.model';
import { ControllerService } from 'src/app/services/controller.service';
import { DateService } from 'src/app/services/date.service';
import { CHOIX, SECTEURS } from '../../constants/list.const';
import { Event } from '../../models/event.model';
import { MobileStateService } from 'src/app/services/mobile-state.service';


@Component({
  selector: 'app-evenement-list',
  templateUrl: './evenement-list.component.html',
  styleUrls: ['./evenement-list.component.css'],
  // tag : perf sidebar
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('newComing', [
      transition(':enter, -1 => *, 0 => *', []),
      transition(':increment', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateX(-100%)' }),
            stagger(-30, [animate('500ms cubic-bezier(0.35,0,0.25,1)', style({ opacity: 1, transform: 'none' }))]),
          ],
          { optional: true },
        ),
      ]),
    ]),
  ],
})
export class EvenementListComponent implements OnInit, OnDestroy, DoCheck, OnChanges {
  @Input() childList: Event[];
  @Input() auFilDeau: Event[];
  @Input() uChoice: ChoiceKeeper = new ChoiceKeeper();
  @Input() dateChanged: boolean;
  @Output() listStates: EventEmitter<any> = new EventEmitter;
  @HostBinding('@.disabled') animationsDisabled: boolean;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport: CdkVirtualScrollViewport;
  dataSet: string[] = [CHOIX.TOUS, CHOIX.CATEGORY, CHOIX.AuFilDeau];
  factors: string[];
  filters: string[] = [];
  labelFromData: string[] = [];
  groupFormControl = new FormControl();
  evtsMap: Map<string, Event[]> = new Map<string, Event[]>();
  faits: Event[] = [];
  dataLength = -1;
  groupChanged: boolean;
  isBackFromDetail: boolean;
  showProgressBar = true;
  originalFilters: string[];
  shouldResetScrollPosition = true;
  panelOpened: boolean;
  loading = false;
  noResults = false;
  isMobile: boolean = false;
  islistclicked: boolean = false;;
  constructor(
    private controlService: ControllerService,
    private cd: ChangeDetectorRef,
    private dateService: DateService,
    private breakpointObserver: MobileStateService

  ) {}

  ngOnInit() {
    // ajout au fil d'eau; // if today and there is some fact;
    this.ajouteAuFilDeau();
    this.filters = [CHOIX.TOUS];
    this.groupFormControl.patchValue(this.uChoice.group);
    this.breakpointObserver.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.breakpointObserver.isMobile();
    });
  }

  // when data coming from parents, this method is called;
  ngOnChanges() {
    if (this.childList) {
      this.showProgressBar = this.childList.length === 0 && this.dateChanged; // this.loading === true;
      this.noResults = this.childList.length === 0 && !this.dateChanged;
    }
    if (this.childList && this.childList.length === 0) {
      this.filters = [];
    }

  }

  ajouteAuFilDeau() {
    if (!this.dateService.isToday()) {
      this.factors = this.dataSet.slice(0, 3);
    } else {
      this.factors = this.dataSet.slice();
    }
  }

  /*
   *Lifecycle hook de angular component, elle détecte régulièrement le changement;
   *Pour éviter de gonfler le front sans cesse, on ne gonfle le front que 'CHOIX changé? && données mis à jour?'
   */
  ngDoCheck() {
    if (
      this.uChoice.group === CHOIX.AuFilDeau &&
      this.dateService.date &&
      this.dateService.date.getDate() !== new Date().getDate()
    ) {
      this.groupFormControl.patchValue(CHOIX.TOUS);
      this.cd.detectChanges();
    }
    if (this.childList && this.viewport && this.uChoice.indexScroll) {
      if (this.shouldResetScrollPosition) {
        this.cd.detectChanges();
        this.viewport.scrollToIndex(this.uChoice.indexScroll, 'smooth');
        this.shouldResetScrollPosition = false;
        this.panelOpened = true;
      }
    }

    this.animationsDisabled = this.controlService.disableAnimation;
    if (this.childList !== undefined && this.childList != null) {
      if (this.groupChanged || this.childList.length !== this.dataLength) {
        // each time entering here, empty the array;
        this.evtsMap.clear();
        this.labelFromData = [];
        this.ajouteAuFilDeau();
        this.getCategoriesFromData();
        this.buildCategoryMapKey();
        this.fullCategoryMapValue();
        this.cd.markForCheck(); // no auto détection, donc, force angular à vérifier lorsque nouveaux faits arrivent;
        const newLength = this.childList.length;
        this.dataLength = newLength;
        this.groupChanged = false;
      }
    }
  }

  getCategoriesFromData() {
    // console.log("filter chosen:"+this.filterChosen);
    if (this.uChoice.group === CHOIX.SECTEUR) {
      // including '' = hor secteur;
      this.childList.forEach((el) => {
        if (this.labelFromData.indexOf(el.secteur) === -1) {
          this.labelFromData.push(el.secteur);
        }
      });

      // ajoute littoral;
      const litto = this.childList.some((el) => {
        if (el.isLittoral !== null && el.isLittoral !== undefined) {
          return el.isLittoral.trim().toLowerCase() === 'true';
        }
        return false;
      });
      if (litto) {
        this.labelFromData.push(SECTEURS.LITTO);
      }

      // ajoute hor secteur;
      const secteurEmpty = this.childList.some((el) => {
        if (el.secteur !== null && el.secteur !== undefined) {
          return el.secteur.trim() === '';
        }
        return false;
      });
      if (secteurEmpty) {
        this.labelFromData.push(SECTEURS.HORS_SECTEUR);
      }
    } else if (this.uChoice.group === CHOIX.CATEGORY) {
      // push tous les catégories, y compris celui des faits s'il y en a;
      this.childList.forEach((el) => {
        if (this.labelFromData.indexOf(el.categorie) === -1) {
          this.labelFromData.push(el.categorie);
        }
      });
    } else if (this.uChoice.group === CHOIX.AuFilDeau) {
      // pas de groupement, affiche tous les evenement et faits qui se terminent à présent;
    }

    // sorting the categories with the alphabet order
    if (this.uChoice.group === CHOIX.CATEGORY) {
      if (this.labelFromData && this.labelFromData.length > 1) {
        this.filters = [];
        this.filters = this.labelFromData.sort((el1, el2) => el1.localeCompare(el2)).slice();
        this.originalFilters = this.labelFromData.slice();
      }
    }
    // sorting the sector with the defined order;
    if (this.uChoice.group === CHOIX.SECTEUR) {
      if (this.labelFromData) {
        this.filters = [];
        const predefinedArr = [SECTEURS.NORD, SECTEURS.CENTRE, SECTEURS.SUD, SECTEURS.LITTO, SECTEURS.HORS_SECTEUR];
        predefinedArr.forEach((el) => {
          if (this.labelFromData.includes(el)) {
            this.filters.push(el);
          }
        });
        // save a copy of this filter content;
        this.originalFilters = this.filters.slice();
      }
    }

    if (this.uChoice.indexSection) {
      if (this.originalFilters) {
        this.filters = [this.originalFilters[this.uChoice.indexSection]];
      }
    } else {
      if (this.originalFilters) {
        this.filters = this.originalFilters.slice();
      }
    }
  }

  /*
   **Construsez la clé de ce map<CHOIX,les données avec ce CHOIX>, 'Tous' par défault;
   */
  buildCategoryMapKey() {
    if (this.uChoice.group === CHOIX.TOUS) {
      this.filters = [CHOIX.TOUS];
    } else if (this.uChoice.group === CHOIX.AuFilDeau) {
      // pas de groupement
      this.filters = [CHOIX.AuFilDeau];
    } else if (this.uChoice.group === CHOIX.CATEGORY) {
      // if today nor not, all is ok;
      this.labelFromData.forEach((category: string) => {
        this.evtsMap.set(category, []);
      });
    } else if (this.uChoice.group === CHOIX.SECTEUR) {
      this.labelFromData.forEach((secteur: string) => {
        this.evtsMap.set(secteur, []);
      });
    }
  }

  fullCategoryMapValue() {
    this.childList.forEach((evt) => {
      if (this.uChoice.group === CHOIX.SECTEUR) {
        const eventsSecteur = this.evtsMap.get(evt.secteur);
        eventsSecteur.push(evt);
        this.evtsMap.set(evt.secteur, eventsSecteur);
        // ajoute litto
        if (evt.isLittoral !== null && evt.isLittoral !== undefined) {
          if (evt.isLittoral.trim().toLowerCase() === 'true') {
            const evtLitto = this.evtsMap.get(SECTEURS.LITTO);
            evtLitto.push(evt);
            this.evtsMap.set(SECTEURS.LITTO, evtLitto);
          }
        }
        // ajoute hor secteur;
        if (evt.secteur !== null && evt.secteur !== undefined) {
          if (evt.secteur.trim() === '') {
            const evtHS = this.evtsMap.get(SECTEURS.HORS_SECTEUR);
            evtHS.push(evt);
            this.evtsMap.set(SECTEURS.HORS_SECTEUR, evtHS);
          }
        }
      } else if (this.uChoice.group === CHOIX.CATEGORY) {
        const natureEvts = this.evtsMap.get(evt.categorie);
        natureEvts.push(evt);
        this.evtsMap.set(evt.categorie, natureEvts);
      } else if (this.uChoice.group === CHOIX.AuFilDeau) {
        // do nothing;
      }
    });
  }

  /*
   **Param défault = 'Tous' ,si non prévu, renvoyez tous les évènements;
   **Exemples:getEventByFilter() et getEventByFilter('Secteur')
   */
  getEventByFilter(filter: string = CHOIX.TOUS) {
    if (this.uChoice.group === CHOIX.TOUS) {
      return this.childList;
    } else if (this.uChoice.group === CHOIX.AuFilDeau) {
      return this.auFilDeau;
    }
    // pour le reste: evts, faits,
    return this.evtsMap.get(filter);
  }

  getGroupedClass() {
    if (this.panelOpened) {
      return this.uChoice.group === CHOIX.CATEGORY && (!this.panelOpened || this.uChoice.indexSection === undefined);
    } else {
      return this.uChoice.group === CHOIX.CATEGORY && this.uChoice.indexSection === undefined;
    }
  }

  /* when changing the group */
  onChooseGroup() {
    const currentValue = this.groupFormControl.value;
    this.groupChanged = currentValue !== this.uChoice.group;
    if (this.groupChanged) {
      this.uChoice.group = currentValue;
      this.uChoice.indexSection = undefined;
      this.uChoice.indexScroll = 0;
      this.shouldResetScrollPosition = true;
      // save state in the service;
      this.controlService.userChoice = this.uChoice;
    }
  }

  getPaddingLeft(): string {
    return this.uChoice.group === CHOIX.TOUS || this.uChoice.group === CHOIX.AuFilDeau ? '24px' : '0px';
  }

  getPaddingRight(): string {
    return this.uChoice.group === CHOIX.TOUS || this.uChoice.group === CHOIX.AuFilDeau ? '16px' : '0px';
  }

  // *****************keep the state when close the list view****************** */
  saveFilterState() {
    this.uChoice.from = 'list';
    this.controlService.userChoice = this.uChoice;
    this.filters = [this.uChoice.group];
  }

  listState($event: boolean){
    if(this.isMobile){
      this.islistclicked = $event;

      this.listStates.emit(this.islistclicked);
    }

  }
  // *****************keep the choice of user***************************** */
  isExpanded(choix: string) {
    if (this.uChoice.indexSection || this.uChoice.indexSection === 0) {
      return choix === this.filters[0];
    }
    return false;
  }

  handler(evt: number) {
    this.uChoice.indexScroll = evt;
  }

  panelExpanded(index: number) {
    this.uChoice.indexSection = index;
    this.filters = [this.originalFilters[index]];

    this.panelOpened = true;
  }

  panelCollapsed(index: number) {
    if (this.originalFilters) {
      this.filters = this.originalFilters.slice();
    }
    this.uChoice.indexSection = undefined;
    this.panelOpened = false;
  }

  // *****************find the right height for it, and hide no related panel*/
  getViewportHeight(containTitle: string): string {
    const heightWindow = window.outerHeight;
    const scrollTriangleHt = 40;
    let contentHeight: number;
    if (containTitle === 'noFilter') {
      contentHeight = heightWindow - 280 - scrollTriangleHt;
    } else {
      contentHeight = heightWindow - 225 - scrollTriangleHt * 2 - 85 - 10;
    }
    return contentHeight + 'px';
  }

  ngOnDestroy() {}
}
