import { DataSource } from '@angular/cdk/table';
import { Legend } from '../models/legend.model';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { CategoryUtil } from '../utils/category.util';
import { filter } from 'rxjs/operators';

export class LegendDataSource implements DataSource<Legend> {
  private legendSubject = new BehaviorSubject<Legend[]>([]);
  private categoryUtils = new CategoryUtil();
  private legends: Legend[];

  loadingLegend() {
    this.legends = [...this.categoryUtils.getLegend()];
    this.legendSubject.next(this.legends);
  }

  connect(collectionViewer: CollectionViewer): Observable<Legend[]> {
    this.loadingLegend();
    return this.legendSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.legendSubject.complete();
  }
}
