// secteur,groupement,catégorie,CHOIX,
export const SECTEURS = {
  TOUS : 'Tous',
  NORD : 'Nord',
  CENTRE : 'Centre',
  SUD : 'Sud',
  LITTO : 'Littoral',
  HORS_SECTEUR : 'Hors Secteur'
};

export const GROUP = {
  TOUS : 'Tous',
  EVENEMENT : 'Évenements',
  OCCUPATIONS : 'Éléments d\'Occupation',
  TRAVAUX : 'Travaux',
  FERMETURES : 'Fermetures',
  FERMETURES_VOIES : 'Fermeture des voies',
};

export const CHOIX = {
  TOUS : 'Tous',
  CATEGORY: 'Catégorie',
  SECTEUR: 'Secteur',
  AuFilDeau : 'Au fil de l\'eau'
};
