import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, DoCheck, OnChanges} from '@angular/core';
import { Event } from '../../models/event.model';
import { BADGE_CONF } from '../../constants/url.const';
import { CategoryUtil } from 'src/app/utils/category.util';
import { ControllerService } from 'src/app/services/controller.service';
import { ID_SOURCES } from '../../constants/idsources.const';
import { TRAFFIC_METADATA, PARKING_METADATA, LANE_CLOSURE_METADATA } from '../../constants/motifs.const';

import { MatSidenav } from '@angular/material';
import {MobileStateService} from 'src/app/services/mobile-state.service';
@Component({
  selector: 'app-evenement-detail',
  templateUrl: './evenement-detail.component.html',
  styleUrls: ['./evenement-detail.component.css']
})
export class EvenementDetailComponent implements OnInit, DoCheck, OnChanges {
  private categoryUtil = new CategoryUtil();
  @Input()
  detailEvt: Event;

  @Output()
  hideDetail: EventEmitter<any> = new EventEmitter();
  @Output() close:  EventEmitter<any> = new EventEmitter();

  eventSource: string;
  eventNature: string;
  isShow: boolean;
  parkingMetadaTab: [string, string][] = [];
  trafficMetadataTab: [string, string][] = [];

  provisionalBadgeText: string;

  deviceMobile:boolean = false;
  constructor(private sidebarService: ControllerService,
              private breakpointObserver: MobileStateService
            ) { }

  ngOnInit() {
    this.provisionalBadgeText = BADGE_CONF.PROVISIONAL_BADGE.TEXT;
    this.breakpointObserver.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.deviceMobile = this.breakpointObserver.isMobile();
    });
  }

  ngOnChanges() {
    if (this.detailEvt &&
      this.detailEvt.adresseNormee !== null &&
      this.detailEvt.adresseNormee !== undefined &&
      this.detailEvt.adresseNormee) {
      this.isShow = this.detailEvt.adresseNormee.trim() !== '' && this.detailEvt.adresseNormee.trim() !== '-';
    }
    this.parkingMetadaTab = [];
    this.trafficMetadataTab = [];
    this.setEventMotifsArray();
    this.detailEvt.setLaneClosureStatus();
  }

  ngDoCheck() {
    if (this.detailEvt) {
      this.eventNature = this.categoryUtil.getDataNature(this.detailEvt.nature);
      const prod = ID_SOURCES[this.detailEvt.idFlux];
      this.eventSource = `${prod} \n ${this.detailEvt.idSource}`;
    }
  }
  seeMap(){

    this.close.emit();
    this.sidebarService.emitEvtDetailState(true);
  }

  emitHideDetail() {
    this.hideDetail.emit();
    this.sidebarService.emitEvtDetailState(false);
  }

  getCategoryColor() {
    return this.categoryUtil.getCategoryBDColor(this.detailEvt.categorie);
  }

  getCategoryFontColor() {
    return this.categoryUtil.getCategoryFontColor(this.detailEvt.categorie);
  }

  getCategoryIcon() {
    return this.categoryUtil.getCategoryIconUrl(this.detailEvt.categorie);
  }

  isEvent() {
    return this.detailEvt.flagType === 'E';
  }

  isProvisionalEvt() {
    return this.detailEvt.statut === 'EVT_A_VALIDER';
  }

  setEventMotifsArray() {
    for (const idEventMetadata of this.detailEvt.displayOptions.slice(1, -1).split(', ')) {
      if (idEventMetadata in PARKING_METADATA) {
        this.parkingMetadaTab.push([idEventMetadata, PARKING_METADATA[idEventMetadata]]);
      } else if (idEventMetadata in TRAFFIC_METADATA) {
        this.trafficMetadataTab.push([idEventMetadata, TRAFFIC_METADATA[idEventMetadata]]);
      }
    }
  }
}
