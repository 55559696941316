import { Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js';

@Injectable({
  providedIn: 'root'
})

export class SseService {
  /*
  * Create EventSource
  * @param url
   */
  constructor() {}
  getEventSource(url: string, options) {
    return new EventSourcePolyfill(url, options);
  }
}
