import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ChoiceKeeper} from 'src/app/models/choice.keeper.model';
import {Filter} from 'src/app/models/filter.model';
import {ControllerService} from 'src/app/services/controller.service';
import {DataService} from 'src/app/services/data.service';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {NEW_FACT_MSG, UPDATING_TIMER} from '../../constants/time.interval';
import {Event} from '../../models/event.model';
import {DateService} from 'src/app/services/date.service';
import {CHOIX, GROUP, SECTEURS} from '../../constants/list.const';
import {Incomp} from 'src/app/models/incomp.model';
import {FilterService} from 'src/app/services/filter.service';
import {PlageTemporelle} from '../../constants/plage.temporelle';
import { MatDialog } from '@angular/material/dialog';
import { DialogBackendDownComponent } from '../dialog-backend-down/dialog-backend-down.component';



import {MobileStateService} from 'src/app/services/mobile-state.service';
import { CategoryUtil } from 'src/app/utils/category.util';


const _SHOW = {
  EVT_INCOMP: 'EVT_INCOMP',
  EVT_DETAIL: 'EVT_DETAIL',
  EVT_LIST: 'EVT_LIST',
  FILTRE: 'FILTRE',
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', {static: true}) matSidenav: MatSidenav;
  private sidebarSubscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  evtChoisi: Event;
  incompChoisi: Incomp;
  evtLeft: Event;
  evtRight: Event;
  parentList: Event[];
  parentAuFilDeau: Event[];
  fulldayEvts: Event[];
  userChoice: ChoiceKeeper = new ChoiceKeeper();
  filter: Filter;
  auFilDeauUpdatingTimer: any;
  indexLastUpdated = -1;
  showSpinner = true;
  _SHOW = _SHOW;
  show = _SHOW.EVT_LIST;
  dateChanged = false;
  suiviTimer: any;
 displayErrorBackendPopUp = false;
  dialogRefErrorBackendPopUp = null;
  private filteredDataSubscription: Subscription;

  deviceMobile:boolean = false;
sidenavenavOpening: boolean = false;
  xSubscription: Subscription;
  isButton1Active: boolean = false;
  isButton2Active: boolean = false;
filterActif:String ="";

isappheaderfade: boolean = false;
private categoryUtil = new CategoryUtil();

  constructor(private controllerService: ControllerService,
              private dataService: DataService,
              private snackBar: MatSnackBar,
              private cd: ChangeDetectorRef,
              private dateService: DateService,
              private filterService: FilterService,
              private mobileStateService: MobileStateService,
	      public dialog: MatDialog
              ) {}

  ngOnInit() {
    this.userChoice.group = this.controllerService.userChoice.group;

    // écoute l'événement cliqué dans la carte;
    this.sidebarSubscription = this.controllerService.evtSubjectSrcMap.subscribe(element => {
      if(this.deviceMobile) {

        this.evtChoisi = element;
        this.show = "";
        this.controllerService.emitEvtDetailState(true);
        this.getEventState(element);
        this.matSidenav.close();
        this.controllerService.userChoice.from = 'map';
        this.cd.detectChanges();


      }else {
        this.evtChoisi = element;
        this.show = _SHOW.EVT_DETAIL;
        this.matSidenav.open();
        this.controllerService.emitEvtDetailState(true);
        this.getEventState(element);
        this.controllerService.userChoice.from = 'map';
        this.cd.detectChanges();
      }
    });

    //filtreMobil
    this.sidebarSubscription.add(this.controllerService.filterMobileSubject.subscribe(isOpen => {
      if (isOpen) {
        this.show = _SHOW.FILTRE;
        this.filterActif='filter-actif';
      }
    })
    );

     this.sidebarSubscription.add(this.mobileStateService.subscribeToLayoutChanges().subscribe(observerResponse => {

      this.deviceMobile = this.mobileStateService.isMobile();
      this.matSidenav.open();
      if(this.deviceMobile) {

        this.matSidenav.close();

      }
    })
     );

    // écoute le clique sur l'incomp dans la carte;
    this.sidebarSubscription.add(this.controllerService.incompSubjectSrcMap.subscribe(incomp => {
      this.incompChoisi = incomp;
      this.initLeftRightEvent(incomp);
      this.show = _SHOW.EVT_INCOMP;
      this.matSidenav.open();
      this.controllerService.emitEvtDetailState(true);
      this.cd.detectChanges();
    }));

    // écoute l'événement cliqué dans la liste;
    this.sidebarSubscription.add(this.controllerService.evtSubjectSrcList.subscribe(element => {
      this.evtChoisi = element;
      this.show = _SHOW.EVT_DETAIL;
      this.matSidenav.open();
      this.controllerService.emitEvtDetailState(true);
      this.cd.detectChanges();
    }));

    // fullday events;
    this.sidebarSubscription.add(this.dataService.fullDayDataSubject.subscribe(evts => this.fulldayEvts = evts));

    // contrôle l'ouverture et fermeture de spinner ici;
    this.sidebarSubscription.add(this.dataService.fullDayDataSubject.subscribe(list => {

      this.showSpinner = list.length === 0;
      this.dateChanged = list.length === 0;
      // vider aussi la liste d'évts;
      if (this.showSpinner) {
        this.parentList = [];
      }
      this.cd.markForCheck();
    }));

    // écoute de l'arrivé des nouveaux données;
    this.filteredDataSubscription = this.dataService.filteredDataSubject.subscribe(filteredEvts => {
      if (!this.suiviTimer) {
        this.addClassicalEventsToParentList(filteredEvts);
      }
    });

    // Listen to Suivi Mode
    combineLatest([this.dataService.filteredDataSubject, this.filterService.filterSubject, this.dataService.trafficDataSubject])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([filteredEventData, filterObj, trafficData]) => {
        const eventData = filteredEventData.slice();
        const filter = filterObj;
        const alertData = this.dataService.filterTrafficData(filter, trafficData).alerts.slice();

        this.filteredDataSubscription.unsubscribe();
        this.addClassicalEventsToParentList(eventData);
      });

   this.dataService.isBackendDown.subscribe(isDown => {
      if (this.displayErrorBackendPopUp !== isDown) {
        this.displayErrorBackendPopUp = isDown;
        if (isDown) {
          this.dialogRefErrorBackendPopUp = this.dialog.open(DialogBackendDownComponent, {
            width: '50%',
            height: '7rem',
            disableClose: true,
          });
        } else {
          this.dialogRefErrorBackendPopUp.close();
        }
      }
    });
  }

  update($event: boolean){

    this.sidenavenavOpening = $event;
    if(this.sidenavenavOpening){

      this.show = _SHOW.EVT_DETAIL;
      this.matSidenav.open();
      this.controllerService.emitEvtDetailState(true);
      this.getEventState(this.evtChoisi);
      this.controllerService.userChoice.from = 'list';
      this.cd.detectChanges();
      this.isappheaderfade = true;
    }

  }

  addClassicalEventsToParentList(event) {
    this.parentList = event;
    this.cd.detectChanges();
    this.lancerAuFilDeau();
  }

  addAlertsToParentList(data, alertData) {
    this.parentList = [];
    const newData = [...data, ...alertData];
    this.parentList = newData.slice();
    this.cd.detectChanges();
    this.lancerAuFilDeau();
  }

  getEventState(evt: Event) {
    if (this.parentList) {
      // get the section index;
      if (this.userChoice.group === CHOIX.CATEGORY) {
        const categoriesSet = new Set<string>();
        this.parentList.forEach(el => {
          categoriesSet.add(el.categorie);
        });
        const categoriesArray = Array.from(categoriesSet);
        categoriesArray.sort();
        const indexOfCategory = categoriesArray.indexOf(evt.categorie);
        if (indexOfCategory !== -1) {
          // this.userChoice.group = 'Catégorie';
          this.userChoice.indexSection = indexOfCategory;
          this.userChoice.indexScroll = undefined;
          this.controllerService.userChoice = this.userChoice;
        }
      } else if (this.userChoice.group === CHOIX.SECTEUR) {
        const secteurSet = new Set<string>();
        this.parentList.forEach(el => {
          secteurSet.add(el.secteur);
        });
        const secteurs = [];
        const predefinedArr = [SECTEURS.NORD, SECTEURS.CENTRE, SECTEURS.SUD, SECTEURS.LITTO, SECTEURS.HORS_SECTEUR];
        const secteurArr = Array.from(secteurSet);
        predefinedArr.forEach(el => {
          if (secteurArr.includes(el)) {
            secteurs.push(el);
          }
        });
        const indexOfSecteur = secteurs.indexOf(evt.secteur);
        if (indexOfSecteur !== -1) {
          this.userChoice.indexSection = indexOfSecteur;
          this.userChoice.indexScroll = undefined;
          this.controllerService.userChoice = this.userChoice;
        }
      }

      // get the scroll index according to the group;
      if (this.userChoice.group === CHOIX.CATEGORY) {
        const categoriesClickedArr = this.parentList
          .filter(el => el.categorie === evt.categorie)
          .sort((first, second) => new Date(second.horodateDebut).getTime() - new Date(first.horodateDebut).getTime());
        const indexOfScrollFromData = categoriesClickedArr.indexOf(evt);
        if (indexOfScrollFromData !== -1) {
          this.userChoice.indexScroll = indexOfScrollFromData;
          this.controllerService.userChoice.indexScroll = indexOfScrollFromData;
        }
      } else if (this.userChoice.group === CHOIX.TOUS) {
        const indexOfScrollFromData = this.parentList.indexOf(evt);
        if (indexOfScrollFromData !== -1) {
          this.userChoice.indexScroll = indexOfScrollFromData;
          this.controllerService.userChoice.indexScroll = indexOfScrollFromData;
        }
      } else if (this.userChoice.group === CHOIX.SECTEUR) {
        const sectorsClickedArr = this.parentList.filter(el => el.secteur === evt.secteur);
        const indexOfScrollFromData = sectorsClickedArr.indexOf(evt);
        if (indexOfScrollFromData !== -1) {
          this.userChoice.indexScroll = indexOfScrollFromData;
          this.controllerService.userChoice.indexScroll = indexOfScrollFromData;
        }
      } else if (this.userChoice.group === CHOIX.AuFilDeau) {
        if (this.parentAuFilDeau) {
          const indexOfScrollFromData = this.parentAuFilDeau.indexOf(evt);
          if (indexOfScrollFromData !== -1) {
            this.userChoice.indexScroll = indexOfScrollFromData;
            this.controllerService.userChoice.indexScroll = indexOfScrollFromData;
          }
        } else {
          this.userChoice.indexScroll = 0;
          this.controllerService.userChoice.indexScroll = 0;
        }

      }
    } else { // when list is not ready; set the default position(impossible, no data in map, user cann't click)
    }
  }

  initLeftRightEvent(incomp: Incomp) {
    if (this.parentList && incomp) {
      this.evtLeft = this.fulldayEvts.find(evt => evt.uid === incomp.uidLeft);
      this.evtRight = this.fulldayEvts.find(evt => evt.uid === incomp.uidRight);
    }
  }

  lancerAuFilDeau() {
    // default charging data;
    this.parentAuFilDeau = this.parentList
      .filter(el => new Date(el.horodateDebut).getTime() < new Date().getTime());
    this.indexLastUpdated = this.parentAuFilDeau.length - 1;

    this.auFilDeauUpdatingTimer = setInterval(() => {
      // updating;
      this.parentAuFilDeau = this.parentList
        .filter(el => new Date(el.horodateDebut).getTime() < new Date().getTime());
      this.indexLastUpdated = this.parentAuFilDeau.length - 1;

      this.updatingAuFilDeau();
    }, UPDATING_TIMER.FREQUENCE * 60 * 1000);
  }

  updatingAuFilDeau() {
    const now = new Date();
    const firstIndex = this.parentList.findIndex(el => new Date(el.horodateDebut).getTime() > now.getTime());
    // if found(!==-1)
    if (firstIndex !== -1 && firstIndex - 1 - this.indexLastUpdated > 0) {
      const quanlifiedArr = this.parentList.slice(this.indexLastUpdated, firstIndex);
      this.parentAuFilDeau.unshift(...quanlifiedArr);
      const lastEvent = this.parentAuFilDeau[this.parentAuFilDeau.length - 1];
      setTimeout(() => this.openSnackBar(new Date(lastEvent.horodateDebut)), 2000);
    }
    // this.cd.detectChanges();
  }

  stopAuFilDeau() {
    if (this.auFilDeauUpdatingTimer) {
      clearInterval(this.auFilDeauUpdatingTimer);
    }
  }

  toggleRightSidenav() {
    this.matSidenav.toggle();
  }

//popup
  closeMatsidnav(sidnav: string){
    if(this.deviceMobile){
      if (sidnav === 'sidnav' && this.controllerService.userChoice.from === 'list') {
      this.matSidenav.close();

      }
      this.isappheaderfade = false;
    }
  }
  hideDetailView(closeView: string) {
    if (closeView === 'detail' && this.controllerService.userChoice.from === 'list') {
      this.show = _SHOW.EVT_LIST;
    } else if (closeView === 'detail' && this.controllerService.userChoice.from === 'incomp') {
      this.show = _SHOW.EVT_INCOMP;
    } else if (closeView === 'incomp' && this.controllerService.userChoice.from === 'incomp') {
      this.show = _SHOW.EVT_LIST;
    } else if (closeView === 'detail' && this.controllerService.userChoice.from === 'map') { // click from map;
      this.show = _SHOW.EVT_LIST;
    } else {
      // au cas où erreur, afficher la liste;
      this.show = _SHOW.EVT_LIST;
    }
    if(this.deviceMobile) {
      this.controllerService.emitEvtDetailState(false);
      this.matSidenav.close();
      this.isappheaderfade = false;
    }
    this.isappheaderfade = false;
  }

  hideFilterView() {

      this.show = "";
      this.filterActif="";

  }

  onClickSidebarBtn() {
    this.show = _SHOW.EVT_LIST;
    this.controllerService.emitEvtDetailState(false);
    this.matSidenav.toggle();
  }

  openSnackBar(faitUpdated: Date) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: NEW_FACT_MSG.DURATION * 60 * 1000,
      panelClass: ['blue-snackbar'],
      data: {date: faitUpdated}
    });
  }

  getSpinnerPosition(): string {
    const winWidth = window.outerWidth;
    const sidenavWidth = 300; // px;
    const halfSizeSpinner = 50; // px
    return this.matSidenav.opened ? ((winWidth - sidenavWidth) / 2 - halfSizeSpinner) + 'px' :
      (winWidth / 2 - halfSizeSpinner) + 'px';
  }

  ngOnDestroy() {
    clearInterval(this.auFilDeauUpdatingTimer);
    this.stopAuFilDeau();
    this.sidebarSubscription.unsubscribe();
    this.destroy$.complete();
    this.destroy$.next();
    if (this.dataService.filterSubscription) {
      this.dataService.filterSubscription.unsubscribe();
    }

  }

  toggleButtonState(button: string){
    this.matSidenav.mode="push";
    if(button === 'carte'){

      this.matSidenav.close();
      this.sidebarSubscription;
      this.controllerService.emitEvtDetailState(false);
    }
    else if(button === 'liste'){
      this.matSidenav.open();
      this.show = _SHOW.EVT_LIST;
      this.controllerService.emitEvtDetailState(false);


    }
  }
  listState($event: boolean){

    this.isappheaderfade = $event;
  }
}
