export class DatePair {
    constructor(private _fromDate?: Date, private _toDate?: Date) {}
    get fromDate(): Date {
        return this._fromDate;
    }
    set fromDate(date: Date) {
        this._fromDate = date;
    }

    get toDate(): Date {
        return this._toDate;
    }

    set toDate(date: Date) {
        this._toDate = date;
    }
}
