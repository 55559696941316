export class Incomp {
  public uidLeft: string;
  public uidRight: string;
  public pourcentageIncompatibilite: number;
  public empriseIncomp: any;
  public horodateDebut: Date;
  public horodateFin: Date;

  constructor(obj?: Incomp) {
    if (obj) {
      this.uidLeft = obj.uidLeft || null;
      this.uidRight = obj.uidRight || null;
      this.pourcentageIncompatibilite = obj.pourcentageIncompatibilite || null;
      this.empriseIncomp = obj.empriseIncomp || null;

      if (obj.horodateDebut && typeof obj.horodateDebut === 'string') {
        this.horodateDebut = new Date(obj.horodateDebut) || null;
      } else {
        this.horodateDebut = obj.horodateDebut || null;
      }

      if (obj.horodateFin && typeof obj.horodateFin === 'string') {
        this.horodateFin = new Date(obj.horodateFin) || null;
      } else {
        this.horodateFin = obj.horodateFin || null;
      }
    } else {
      console.log('incomp object est null');
    }
  }

}
