import { Component, OnInit, Output, EventEmitter, DoCheck, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DateService } from '../../../services/date.service';
import { MenuStateService } from '../../../services/menu-state.service';
import { DataService } from 'src/app/services/data.service';
import { PlageTemporelle } from '../../../constants/plage.temporelle';
import { FilterService } from 'src/app/services/filter.service';
import { DatePair } from 'src/app/models/date.pair.model';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header-day',
  templateUrl: './header-day.component.html',
  styleUrls: ['./header-day.component.css'],
  animations: [
    trigger('openClose', [
      state(
        'btnOpen',
        style({
          backgroundColor: 'green',
        }),
      ),
      state(
        'btnClose',
        style({
          backgroundColor: 'yellow',
        }),
      ),
      transition('btnOpen => btnClose', animate('1s 0.2s ease-in')),
      transition('btnClose => btnOpen', animate('1s 0.5s')),
    ]),
  ],
})
export class HeaderDayComponent implements OnInit, DoCheck, OnDestroy {
  btnDefaultColor = '#fff';
  btnPressedColor = '#00AAFF';
  colorDefiner: boolean[] = [];
  timerForUpdateTime = null;
  timerForUpdateData = null;
  timerInit = null;
  dateEffective: Date;
  sliderDefaultValue: number;
  timeWithFormat: string;
  isHidden = true;
  onSuivi = false;
  @Output()
  suivi = new EventEmitter<boolean>();
  datePair = new DatePair();

  constructor(
    private dateService: DateService,
    private menuStateService: MenuStateService,
    private dataService: DataService,
    private filterService: FilterService,
    private cd: ChangeDetectorRef,
  ) {
    this.colorDefiner = this.menuStateService.menuStates;
    if (!filterService.filter.plageTemporelle) {
      this.filterService.setFilterPlageTemporelle(PlageTemporelle.Jour);
    }
  }

  ngOnInit() {
    if (this.filterService.filter.plageTemporelle === PlageTemporelle.Ponctuel) {
      this.onSuivi = false;
      this.isHidden = false;
      this.updateRightNowTime(false);
    }
  }

  updateRightNowTime(showSecond: boolean) {
    const nowTime = new Date();
    this.sliderDefaultValue = (nowTime.getHours() * 60 + nowTime.getMinutes() * 1) / 5;
    this.timeWithFormat = this.timerFormater(nowTime.getHours(), nowTime.getMinutes(), nowTime.getSeconds(), showSecond);
    this.cd.markForCheck();
  }

  ngDoCheck() {
    if (this.dateService.date) {
      this.dateEffective = this.dateService.date;
    } else {
      this.dateEffective = new Date();
    }
  }

  onSlide(event: any) {
    this.isHidden = false;
    const hour = Math.floor(((event.value + 0) * 5) / 60);
    const minute = ((event.value + 0) * 5) % 60;
    this.setSliderPosition(hour, minute);
    this.timeWithFormat = this.timerFormater(hour, minute, 0, false);
    this.sendSliderFilter(hour, minute);
  }

  sendSliderFilter(hour: number, minute: number) {
    if (this.dateService.date) {
      this.datePair = this.dateService.buildSliderDate(hour, minute);
    } else {
      this.datePair = this.dateService.buildSliderDate(hour, minute);
    }
    this.filterService.setFilterFromToDate(this.datePair.fromDate, this.datePair.toDate);
  }

  onPontuel(): void {
    this.isHidden = false;
    this.onSuivi = false;
    this.suivi.emit(false);
    // set the time and slider position; default position;
    const rightNow = new Date();
    const hour = rightNow.getHours();
    const minute = rightNow.getMinutes();
    this.setSliderPosition(hour, minute);

    this.menuStateService.updateValue(1);
    this.colorDefiner = this.menuStateService.menuStates;
    this.deleteTimers();
    this.filterService.setFilterPlageTemporelle(PlageTemporelle.Ponctuel);
    this.filterData(PlageTemporelle.Ponctuel);
  }
  onFullDay(): void {
    this.isHidden = true;
    this.suivi.emit(false);
    this.menuStateService.updateValue(2);
    this.colorDefiner = this.menuStateService.menuStates;
    this.filterService.setFilterPlageTemporelle(PlageTemporelle.Jour);
    this.filterData(PlageTemporelle.Jour);
    this.deleteTimers();
  }
  onMorning(): void {
    this.isHidden = true;
    this.suivi.emit(false);
    this.menuStateService.updateValue(3);
    this.colorDefiner = this.menuStateService.menuStates;
    this.filterService.setFilterPlageTemporelle(PlageTemporelle.Matin);
    this.filterData(PlageTemporelle.Matin);
    this.deleteTimers();
  }
  onAfternoon(): void {
    this.isHidden = true;
    this.suivi.emit(false);
    this.menuStateService.updateValue(4);
    this.colorDefiner = this.menuStateService.menuStates;
    this.filterService.setFilterPlageTemporelle(PlageTemporelle.Aprem);
    this.filterData(PlageTemporelle.Aprem);
    this.deleteTimers();
  }
  onNight(): void {
    this.isHidden = true;
    this.suivi.emit(false);
    this.menuStateService.updateValue(5);
    this.colorDefiner = this.menuStateService.menuStates;
    this.filterService.setFilterPlageTemporelle(PlageTemporelle.Nuit);
    this.filterData(PlageTemporelle.Nuit);
    this.deleteTimers();
  }

  filterData(index: string): void {
    const res = this.dateService.setFilterTimeInterval(this.dateEffective, index);
    this.filterService.setFilterFromToDate(res.fromDate, res.toDate);
  }

  setSliderPosition(hour: number, minute: number) {
    this.sliderDefaultValue = Math.round((hour * 60 + minute * 1) / 5);
    this.timeWithFormat = this.timerFormater(hour, minute, 0, false);
  }

  updateButtonsColor(index: number) {
    return this.colorDefiner[index] ? this.btnPressedColor : this.btnDefaultColor;
  }

  timerFormater(hours: number, minutes: number, seconds: number, isShowSeconds: boolean): string {
    let hr = '';
    let ms = '';
    let ss = '';
    hr = hours < 10 ? `0${hours}` : `${hours}`;
    ms = minutes < 10 ? `0${minutes}` : `${minutes}`;
    ss = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return isShowSeconds ? `${hr}:${ms}:${ss}` : `${hr}:${ms}`;
  }

  deleteTimers() {
    clearInterval(this.timerForUpdateTime);
    clearInterval(this.timerInit);
  }

  ngOnDestroy() {
    this.deleteTimers();
  }
}
