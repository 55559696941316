import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DatePair } from '../models/date.pair.model';
import { PlageTemporelle } from '../constants/plage.temporelle';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private currentDate: Date = new Date();
  private dateChosen: Date;
  public dateChangedSubject: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  get date() {
    return this.dateChosen;
  }

  set date(date: Date) {
    this.dateChosen = date;
  }

  isToday(): boolean {
    if (!this.date) { return true; }
    const date = new Date(this.date);
    const today = new Date();
    return date.getUTCDate() === today.getUTCDate() && date.getUTCMonth() === today.getUTCMonth() &&
           date.getUTCFullYear() === today.getUTCFullYear();
  }

  buildFromToDate(date: Date, fromHour: number, fromMinute: number, toHour: number, toMinute: number): DatePair {
    // console.log("param from h:"+fromHour+",from m:"+fromMinute+",to h:"+toHour+",to m:"+toMinute);
    const targetDate = this.currentDate;
    if (date) {
      targetDate.setFullYear(date.getFullYear());
      targetDate.setMonth(date.getMonth());
      targetDate.setDate(date.getDate());
    }
    targetDate.setHours(fromHour);
    targetDate.setMinutes(fromMinute);
    targetDate.setSeconds(0);

    //JBI:07052024: suppression des millisecondes
    const  utcDateString = targetDate.toISOString();
    const newToDateWithoutMs = new Date(utcDateString.slice(0, -5) + "Z");
    const fromDate = new Date(newToDateWithoutMs.getTime());

    // to date;
    targetDate.setHours(toHour);
    targetDate.setMinutes(toMinute);
    targetDate.setSeconds(0);
    const newToDate = new Date(targetDate);
    newToDate.setDate(newToDate.getUTCDate() + 1);
    const toDate = new Date(newToDate.getTime());
    return new DatePair(fromDate, toDate);
  }

  buildSliderDate(fromHour: number, fromMinute: number): DatePair {
    const targetDate = this.currentDate;
    let fromDate: Date;
    let toDate: Date;
    if (this.date) {
      targetDate.setFullYear(this.date.getUTCFullYear());
      targetDate.setMonth(this.date.getUTCMonth());
      targetDate.setDate(this.date.getUTCDate());
    }
    targetDate.setHours(fromHour);
    targetDate.setMinutes(fromMinute);
    targetDate.setSeconds(0);
    fromDate = new Date(targetDate);

    targetDate.setHours(fromHour);
    targetDate.setMinutes(fromMinute + 5);
    targetDate.setSeconds(0);
    toDate = new Date(targetDate);

    return new DatePair(fromDate, toDate);
  }

  setFilterTimeInterval(date: Date, index: string) {
    let horodateInf = new Date(date);
    horodateInf.setMinutes(0);
    horodateInf.setSeconds(0);

    let horodateSup = new Date(date);
    horodateSup.setMinutes(0);
    horodateSup.setSeconds(0);
    switch (index) {
      case PlageTemporelle.Jour: {
        horodateInf.setHours(0);
        const newDateSup = new Date(horodateSup);
        newDateSup.setDate(horodateSup.getDate());
        horodateSup = newDateSup;
        horodateSup.setHours(23);
        horodateSup.setMinutes(59);
        horodateSup.setSeconds(59);
        break;
      }
      case PlageTemporelle.Matin: {
        horodateInf.setHours(6);
        horodateSup.setHours(13);
        break;
      }
      case PlageTemporelle.Aprem: {
        horodateInf.setHours(13);
        horodateSup.setHours(20);
        break;
      }
      case PlageTemporelle.Nuit: {
        horodateInf.setHours(20);
        // Plages horaires nuit : de J 20h:00 à J+1 06:00
        const newhorodateSup = new Date(horodateSup);
        newhorodateSup.setDate(horodateSup.getUTCDate() + 1);
        horodateSup = newhorodateSup;
        horodateSup.setHours(6);
        // horodateSup.setMinutes(59);
        break;
      }

      case PlageTemporelle.Ponctuel: {
        const now = new Date();
        horodateInf.setHours(now.getUTCHours());
        horodateInf.setMinutes(now.getUTCMinutes());
        horodateSup.setHours(now.getUTCHours());
        horodateSup.setMinutes(now.getUTCMinutes() + 5);
        break;
      }

      default: break;
    }
    return new DatePair(horodateInf, horodateSup);
  }
}
