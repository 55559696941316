import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatExpansionModule, MatSidenavModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
    imports: [MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSliderModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        MatSidenavModule,
        MatTreeModule,
        MatTabsModule,
        MatTableModule,
        MatCardModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatBottomSheetModule,
        MatListModule,
        MatBadgeModule
    ],
    exports: [MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSliderModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        MatSidenavModule,
        MatTreeModule,
        MatTabsModule,
        MatTableModule,
        MatCardModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatBottomSheetModule,
        MatListModule,
        MatBadgeModule
    ],
})
export class MaterialModule { }
