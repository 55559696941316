/* angular module */
import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* third party modules */
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClickOutsideModule } from 'ng-click-outside';
import { Ng5SliderModule } from 'ng5-slider';
import { AppRoutingModule } from './app-routing.module';

/* self defined component */
import { AppComponent } from './components/app/app.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EvenementDetailComponent } from './components/evenement-detail/evenement-detail.component';
import { EvenementListComponent } from './components/evenement-list/evenement-list.component';
import { EventOverviewComponent } from './components/event-overview/event-overview.component';
import { HeaderCalendarComponent } from './components/headers/header-calendar/header-calendar.component';
import { HeaderDayComponent } from './components/headers/header-day/header-day.component';
import { HeaderTopComponent } from './components/headers/header-top/header-top.component';
import { HeadersComponent } from './components/headers/headers.component';
import { LegendComponent } from './components/legend/legend.component';
import { MapComponent } from './components/map/map.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { KeysPipe } from './pipe/keys.pipe';
import { MyDatePipe } from './pipe/myDate.pipe';
import { MaterialModule } from './res/material';
/* self defined service */
import { DataService } from './services/data.service';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { OBDTPMDefs } from './constants/url.const';
import { AppConfigService } from './services/app.config.service';
import { DialogDeleteComponent } from './components/dialog-delete/dialog-delete.component';
import { IncompDetailComponent } from './components/incomp-detail/incomp-detail.component';
import { StickyTableHeaderDirective } from './pipe/sticky-table-header.directive';
import { DialogBackendDownComponent } from './components/dialog-backend-down/dialog-backend-down.component';

registerLocaleData(localeFr, 'fr');

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderCalendarComponent,
    HeaderDayComponent,
    HeadersComponent,
    MapComponent,
    EvenementListComponent,
    SidebarComponent,
    EvenementDetailComponent,
    EventOverviewComponent,
    KeysPipe,
    LegendComponent,
    DialogComponent,
    DialogDeleteComponent,
    DialogBackendDownComponent,
    SplashScreenComponent,
    MyDatePipe,
    SnackbarComponent,
    HeaderTopComponent,
    IncompDetailComponent,
    StickyTableHeaderDirective


  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    LeafletModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    Ng5SliderModule,
    ClickOutsideModule,
    NgxChartsModule,
    ScrollingModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonToggleModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    DataService,
    OBDTPMDefs,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [AppConfigService],
      multi: true
    }
  ],
  entryComponents: [DialogComponent, DialogDeleteComponent, SnackbarComponent, DialogBackendDownComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
