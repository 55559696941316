import * as L from 'leaflet';
import { AppConfigService } from '../services/app.config.service';
import { Injectable } from '@angular/core';

@Injectable()
export class OBDTPMDefs {

  constructor(private appConfigService: AppConfigService) { }

  public MAP_DEF = new Map([
    [
      'VDM-C',
      {
        is_default:  this.setDefaultMap(this.appConfigService.getAppConfig().env, 'VDM-C'),
        label: 'Carte couleur',
        type: 'MAP_PUBLIC',
        url: this.appConfigService.getAppConfig().map_base_layer_4,
        props: { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', minZoom: 0, maxZoom: 18},
        showIn: ['INTEG', 'PREPROD', 'PROD'],
      },
    ],
  ]);

  public MAP_CTRE: L.LatLng = new L.LatLng(43.296482, 5.36978);
  public MAP_ZOOM = 11;

  private setDefaultMap(environmentType: string, typeMap: string): boolean {
    if (typeMap === 'VDM-C') { return true; }
    return false;
  }
}

/* *************************Heat map configuration***************************

1.lien Doc:
https://www.patrick-wied.at/static/heatmapjs/docs.html

2.Blur:
Le facteur de flou qui sera appliqué à tous les points de données. Plus le facteur de flou est élevé, plus les gradients seront lisses.

3.Point de donnée{lat,lng,noteRisque}, voir le modèle:heat.point.model.ts

4.HEATMAP_DATA_MIN,HEATMAP_DATA_MAX sont des valeurs min et max des données respectivement.
*/

// TODO transforemer la classe en constante
export class HEATMAPConf {
  public HEATMAP_RADIUS = 0.005;
  public HEATMAP_OPACITY = 0.5;
  public HEATMAP_BLUR = 0.95;
  public HEATMAP_DATA_MIN = 0;
  public HEATMAP_DATA_MAX = 100;
  // public HEATMAP_DATA_MAX:number = 100;
  public HEATMAP_COLOR = [
    { color: '#0a2265', gradiant: '.9' },
    { color: '#156789', gradiant: '.6' },
    { color: '#6cbba4', gradiant: '.25' },
    { color: '#92EC2C', gradiant: '.1' },
  ];
}


/* *************************Taille Caméra ***************************/
export const CAMERA_SIZE = 20;
/* ************************* NEW_DATA_PARAM en second ********/
export const NEW_DATA_PARAM = {
  DURATION : 10,
};



/* ************************* Marqeur taille config ********/
export const MARKER_CONF = {
  ICON_INIT_SIZE : 24,
  ICON_DEFAULT_RATIO : 1.275, // Ratio calculé par mesuré le heuter/largeur des images originales;
  ICON_CLICKED_GRANDIR_RATIO : 2.2,
};

/* ************************* Badge config ********/
export const BADGE_CONF = {
  PROVISIONAL_BADGE: {
    TEXT: 'Provisoire'
  }
};
