import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuStateService {
  menuStates: boolean[] = [false, false, true, false, false, false];
  // the state of array correspond the activation or not of follow,pontuel,day,morning,afternoon,night respectively
  updateValue(index: number) {
    this.menuStates = this.menuStates.map((e) => (e = false));
    this.menuStates[index] = true;
  }
}
