import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {

  private appConfig;

  constructor(private http: HttpClient) {}

  getAppConfig() {
    return this.appConfig;
  }

  loadAppConfig() {
    const jsonFile = 'assets/config.json';
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(jsonFile)
        .toPromise()
        .then((response) => {
          this.appConfig = response;
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }

}
