import {Ranger} from '../models/ranger.model';

export class Filter {
  private _categories: string[] = [];
  private _secteurs: string[] = [];
  private _fromDate: Date;
  private _toDate: Date;
  private _plageTemporelle: string;
  private _noteRisque: Ranger;
  private _incomp: boolean;
  private _provisoire: boolean;

  constructor() {}

  get categories(): string[] {
    return this._categories;
  }

  set categories(categories) {
    this._categories = categories;
  }

  get secteurs(): string[] {
    return this._secteurs;
  }

  set secteurs(secteurs: string[]) {
    this._secteurs = secteurs;
  }

  get fromDate(): Date {
    return this._fromDate;
  }

  set fromDate(date: Date) {
    this._fromDate = date;
    // console.log("from date in filter instance:"+date);
  }

  get toDate(): Date {
    return this._toDate;
  }

  set toDate(date: Date) {
    this._toDate = date;
    // console.log("to date in filter instance:"+date);
  }

  get plageTemporelle(): string {
    return this._plageTemporelle;
  }

  set plageTemporelle(temporelle: string) {
    this._plageTemporelle = temporelle;
  }

  get noteRisque() {
    return this._noteRisque;
  }

  set noteRisque(noteRisk: Ranger) {
    this._noteRisque = noteRisk;
  }

  get incompChecked() {
    return this._incomp;
  }

  set incompChecked(incomp: boolean) {
    this._incomp = incomp;
  }

  get provisoireChecked() {
    return this._provisoire;
  }

  set provisoireChecked(provisoire: boolean) {
    this._provisoire = provisoire;
  }

  /*   toString():string{
        return 'natures:'+this._categories.toString()+
        ',secteurs:'+this._secteurs.toString()+
        ',from data:'+this._fromDate.toDateString()+
        ',to data:'+this._toDate.toDateString()+
        ',plage temporelle:'+this._plageTemporelle+
        ',note risk:'+this._noteRisque.toString()+
        ',note incomp:'+this._noteIncomp.toString();
    } */
}
