import { LANE_CLOSURE_METADATA } from '../constants/motifs.const';

export class Event {
  public uid: string;
  public idFlux: string;
  public idSource: string;
  public horodateDebut: Date;
  public horodateFin: Date;
  public horodateImp: Date;
  public nom: string;
  public categorie: string;
  public type: string;
  public statut: string;
  public adresseNormee: string;
  public emprise: string;
  public empriseImpact: string;
  public latitude: number;
  public longitude: number;
  public secteur: string;
  public isLittoral: string;
  public nature: string;
  public nbPers: string;
  public noteIncompatibilite: number;
  public noteRisque: number;
  public flagType: string;
  public tauxCouvertureCameras: number;
  public displayOptions: string;
  public laneClosureStatus: string;

  constructor(obj?: Event) {
    if (obj) {
      this.idFlux = obj.idFlux || null;
      this.idSource = obj.idSource || null;
      this.adresseNormee = obj.adresseNormee || null;
      this.categorie = obj.categorie || null;
      this.emprise = obj.emprise || null;
      this.empriseImpact = obj.empriseImpact || null;
      this.isLittoral = obj.isLittoral || null;
      this.nbPers = obj.nbPers || null;
      this.idSource = obj.idSource || null;
      this.latitude = obj.latitude || null;
      this.longitude = obj.longitude || null;
      this.nature = obj.nature || null;
      this.nom = obj.nom || null;
      this.secteur = obj.secteur || null;
      this.type = obj.type || null;
      this.statut = obj.statut || null;
      this.noteIncompatibilite = obj.noteIncompatibilite || null;
      this.noteRisque = obj.noteRisque || null;
      this.flagType = obj.flagType || null;
      this.uid = obj.uid || null;
      this.tauxCouvertureCameras = obj.tauxCouvertureCameras || null;
      this.displayOptions = obj.displayOptions || null;
      this.laneClosureStatus = obj.displayOptions || null;

      if (obj.horodateDebut && typeof obj.horodateDebut === 'string') {
        this.horodateDebut = new Date(obj.horodateDebut) || null;
      } else {
        this.horodateDebut = obj.horodateDebut || null;
      }

      if (obj.horodateFin && typeof obj.horodateFin === 'string') {
        this.horodateFin = new Date(obj.horodateFin) || null;
      } else {
        this.horodateFin = obj.horodateFin || null;
      }

      if (obj.horodateImp && typeof obj.horodateImp === 'string') {
        this.horodateImp = new Date(obj.horodateImp) || null;
      } else {
        this.horodateImp = obj.horodateImp || null;
      }
    } else {
      console.log('event object est null');
    }
  }

  public setLaneClosureStatus(): void {
    this.laneClosureStatus = 'E_OPEN_LANE';
    for (const idEventMetadata of this.displayOptions.slice(1, -1).split(', ')) {
      if (LANE_CLOSURE_METADATA[idEventMetadata] === 'E_CLOSE_LANE') {
        this.laneClosureStatus = 'E_CLOSE_LANE';
        break;
      } else if (LANE_CLOSURE_METADATA[idEventMetadata] === 'E_HALF_CLOSE_LANE') {
        this.laneClosureStatus = 'E_HALF_CLOSE_LANE';
      }
    }
  }
}
