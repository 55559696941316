export const TRAFFIC_METADATA: {[idEventMetada: string]: string} = {
    AIPIETON:   'Aire Piétonne',
    AB3AFEUX:	'Balise sous feux',
    CARFEUX:	'Carrefour à feux',
    A25:	    'Carrefour a sens giratoire',
    INDO:	    'Carrefour Indonésien',
    AB3A:	    'Cédez le passage',
    CHA:	    'Chantiers Annuels',
    CHM:	    'Chantiers Mobiles',
    A3:	        'Chaussée rétrécie',
    A18:	    'Circulation alternée',
    CIRCAU:	    'Circulation Autorisée',
    CPIET:	    'Circulation des piétons',
    B0:	        'Circulation interdite',
    B0FEU:	    'Circulation interdite sauf pompiers',
    B0NE:	    'Circulation neutralisé',
    B01V:	    'Circulation sur une voie',
    CTI:	    'Circulation Trottoir Interdit',
    B27a:	    'Couloir réservé aux transports en commun',
    DSC:	    'Double Sens Cyclable',
    A17:	    'Feux tricolores',
    B12:	    'Hauteur des véhicules',
    B3:	        'Interdiction de dépasser',
    B2C:	    'Interdiction de faire demi-tour',
    B2B:	    'Interdiction de trourner à droite',
    B2A:	    'Interdiction de trourner à gauche',
    AB25:	    'Intersection giratoire',
    B9a:	    'La Circulation est interdite aux piétons',
    B11:	    'Largeur des véhicules',
    B10a:	    'Longueur des véhicules',
    B21B:	    'Obligation d\'aller tout droit',
    B21:	    'Obligation de contourner le refuge',
    B21C1:	    'Obligation de tourner à droite',
    B21C2:	    'Obligation de tourner à gauche',
    PPFEUX:	    'Passage Piétons à feux',
    B22a:	    'Piste ou Bande Cyclable',
    B13a:	    'Poids total en charge supérieur à',
    AB2:	    'Route prioritaire',
    B1:	        'Sens interdit',
    C12:	    'Sens unique',
    C18:	    'Sens unique alterné',
    AB4:	    'Signal "Stop"',
    R24:	    'Signal d\'arrêt feu rouge clignotant',
    B13C:	    'Transport mat. dangeureuses',
    B14:	    'Vitesse limitée à ',
    B27b:	    'Voie réservé aux Tramway',
    C115:	    'Voie Verte',
    C107:	    'Voies pour automobiles',
    ROUGE:	    'Voies Rouges',
    ZONE:	    'Zone 30',
    B52:        'Zone de rencontre'
};

export const PARKING_METADATA: {[idEventMetada: string]: string} = {
    NUM:	    'Numérotation',
    M6i:	    'Alvéole Electrique',
    B6D1:	    'Arrêt interdit',
    CARS:	    'Autocars',
    C9:	        'Autopartage',
    DEMI:	    'Dépose minute',
    B6D:	    'L\'arrêt et le stationnement sont interdits, et considérés comme gênants',
    C1A:	    'Parc de stationnement',
    C1C:	    'Parc en enclos payant',
    B6A:	    'Stationnement alterné',
    SBC:	    'Stationnement autorisé',
    B6A1:	    'Stationnement interdit',
    B6B3:	    'Stationnement interdit plus de 15 minutes',
    MUT:	    'Stationnement Mutualisé',
    B6B4:	    'Stationnement payant',
    RESERVE:    'Stationnement réservé',
    C1M4C:	    'Stationnement réservé aux deux roues',
    C1M4N:	    'Stationnement réservé aux personnes handicapées',
    VELO:	    'Stationnement réservé aux vélos',
    LIV:	    'Stationnement réservé livraison',
    TAXI:	    'Stationnement réservé taxi',
    TRANSFON:   'Stationnement réservé transport de fond',
    RTM:        'Stationnement RTM'
};

/*******************  Légende *******************/
/*  E_OPEN_LANE: Aucune incidence sur les voies       */
/*  E_HALF_CLOSE_LANE: Fermeture partielle des voies       */
/*  E_CLOSE_LANE: Ferme complète des voies               */
export const LANE_CLOSURE_METADATA: {[idEventMetada: string]: string} = {
    AIPIETON:   'E_OPEN_LANE',
    AB3AFEUX:	'E_OPEN_LANE',
    CARFEUX:	'E_OPEN_LANE',
    A25:	    'E_OPEN_LANE',
    INDO:	    'E_OPEN_LANE',
    AB3A:	    'E_OPEN_LANE',
    CHA:	    'E_OPEN_LANE',
    CHM:	    'E_OPEN_LANE',
    A3:	        'E_HALF_CLOSE_LANE',
    A18:	    'E_HALF_CLOSE_LANE',
    CIRCAU:	    'E_OPEN_LANE',
    CPIET:	    'E_OPEN_LANE',
    B0:	        'E_CLOSE_LANE',
    B0FEU:	    'E_CLOSE_LANE',
    B0NE:	    'E_CLOSE_LANE',
    B01V:	    'E_HALF_CLOSE_LANE',
    CTI:	    'E_OPEN_LANE',
    B27a:	    'E_OPEN_LANE',
    DSC:	    'E_OPEN_LANE',
    A17:	    'E_OPEN_LANE',
    B12:	    'E_OPEN_LANE',
    B3:	        'E_OPEN_LANE',
    B2C:	    'E_OPEN_LANE',
    B2B:	    'E_OPEN_LANE',
    B2A:	    'E_OPEN_LANE',
    AB25:	    'E_OPEN_LANE',
    B9a:	    'E_OPEN_LANE',
    B11:	    'E_OPEN_LANE',
    B10a:	    'E_OPEN_LANE',
    B21B:	    'E_OPEN_LANE',
    B21:	    'E_OPEN_LANE',
    B21C1:	    'E_OPEN_LANE',
    B21C2:	    'E_OPEN_LANE',
    PPFEUX:	    'E_OPEN_LANE',
    B22a:	    'E_OPEN_LANE',
    B13a:	    'E_OPEN_LANE',
    AB2:	    'E_OPEN_LANE',
    B1:	        'E_OPEN_LANE',
    C12:	    'E_OPEN_LANE',
    C18:	    'E_OPEN_LANE',
    AB4:	    'E_OPEN_LANE',
    R24:	    'E_OPEN_LANE',
    B13C:	    'E_OPEN_LANE',
    B14:	    'E_OPEN_LANE',
    B27b:	    'E_OPEN_LANE',
    C115:	    'E_OPEN_LANE',
    C107:	    'E_OPEN_LANE',
    ROUGE:	    'E_OPEN_LANE',
    ZONE:	    'E_OPEN_LANE',
    B52:        'E_OPEN_LANE',
    NUM:	    'E_OPEN_LANE',
    M6i:	    'E_OPEN_LANE',
    B6D1:	    'E_OPEN_LANE',
    CARS:	    'E_OPEN_LANE',
    C9:	        'E_OPEN_LANE',
    DEMI:	    'E_OPEN_LANE',
    B6D:	    'E_OPEN_LANE',
    C1A:	    'E_OPEN_LANE',
    C1C:	    'E_OPEN_LANE',
    B6A:	    'E_OPEN_LANE',
    SBC:	    'E_OPEN_LANE',
    B6A1:	    'E_OPEN_LANE',
    B6B3:	    'E_OPEN_LANE',
    MUT:	    'E_OPEN_LANE',
    B6B4:	    'E_OPEN_LANE',
    RESERVE:    'E_OPEN_LANE',
    C1M4C:	    'E_OPEN_LANE',
    C1M4N:	    'E_OPEN_LANE',
    VELO:	    'E_OPEN_LANE',
    LIV:	    'E_OPEN_LANE',
    TAXI:	    'E_OPEN_LANE',
    TRANSFON:   'E_OPEN_LANE',
    RTM:        'E_OPEN_LANE'
};
