export class ChoiceKeeper {
  private _group: string; // catégorie ou secteur
  private _indexSection: number; // dans quel catégorie ou dans quel secteur,
  private _indexScroll: number; // dans une catégorie spécifique, item se trouve dans quelle ordre;
  private _from: string; // titre

  constructor(group?: string, indexScroll: number= 0) {
    if (group) { this._group = group; }
    this._indexScroll = indexScroll;
  }

  get from() {
    return this._from;
  }

  set from(item: string) {
    this._from = item;
  }

  get group() {
    return this._group;
  }
  set group(choix: string) {
    this._group = choix;
  }

  get indexSection() {
    return this._indexSection;
  }
  set indexSection(ind: number) {
    this._indexSection = ind;
  }

  get indexScroll() {
    return this._indexScroll;
  }

  set indexScroll(index: number) {
    this._indexScroll = index;
  }
}
