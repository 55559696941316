export const INDEX = {
  GLOBAL: 'global',
  NBEVTS: 'nbevts',
  NBPERS: 'nbpers',
  CONTEXT: 'contexte',
  GLOBAL_USER: 'global_user',
  /* à enlever ! */
  DENSITE: 'densite',
  NParti: 'nbParticipants'

};

export const COLOR = {0: 'gray', 1: '#92EC2C', 2: '#6cbba4', 3: '#156789', 4: '#0a2265'};

export const TEXT = {0: 'indisponible', 1: 'paisible', 2: 'calme', 3: 'riche', 4: 'intense'};

export class DpirUtil {
  // prettier-ignore
  dpirMap = new Map([
      [INDEX.GLOBAL     , { code_name: INDEX.GLOBAL,      image: '' , colors: COLOR, text: TEXT}],
      [INDEX.NBEVTS     , { code_name: INDEX.DENSITE,     image: '' , colors: COLOR, text: TEXT}],
      [INDEX.NBPERS     , { code_name: 'participants',    image: '' , colors: COLOR, text: TEXT}],
      [INDEX.CONTEXT    , { code_name: INDEX.CONTEXT,     image: '' , colors: COLOR, text: TEXT}],
      [INDEX.GLOBAL_USER, { code_name: INDEX.GLOBAL_USER, image: '' , colors: COLOR, text: TEXT}],
      [INDEX.DENSITE    , { code_name: INDEX.DENSITE,     image: '' , colors: COLOR, text: TEXT}],
      [INDEX.NParti     , { code_name: INDEX.NParti,      image: '' , colors: COLOR, text: TEXT}],
    ]);

  getNatureIcon(dpir: string) {
    return this.dpirMap.get(dpir).image;
  }

  getColorBy(note: number, dpir: string) {
    if (dpir != null) {
      return this.dpirMap.get(dpir).colors[note];
    }
    return 'grey';
  }

  getCodeName(dpir: string) {
    switch (dpir) {
      case INDEX.NBEVTS: return 'Densité d\'événement';
      case INDEX.NBPERS: return 'Nombre de participants';
      default: return 'Info';
    }
  }

  getCode(dpir: string) {
    switch (dpir) {
      case INDEX.NBEVTS: return 'Densité';
      case INDEX.NBPERS: return 'Participants';
      default: return 'N/A';
    }
  }

  getTextBy(dpir: string, note: number) {
    if (dpir != null) {
      return this.dpirMap.get(dpir).text[note];
    }
    return 'Indisponible';
  }
}
