import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-backend-down',
  templateUrl: './dialog-backend-down.component.html',
  styleUrls: ['./dialog-backend-down.component.css'],
})
export class DialogBackendDownComponent {
  constructor(
  ) {}
}
