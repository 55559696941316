import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Incomp } from 'src/app/models/incomp.model';
import { CategoryUtil } from 'src/app/utils/category.util';
import { Event } from 'src/app/models/event.model';
import { Subscription } from 'rxjs';
import { ControllerService } from 'src/app/services/controller.service';

@Component({
  selector: 'app-incomp-detail',
  templateUrl: './incomp-detail.component.html',
  styleUrls: ['./incomp-detail.component.css']
})
export class IncompDetailComponent implements OnInit {
  @Input()
  detailIncomp: Incomp;
  @Input()
  evtLeft: Event;
  @Input()
  evtRight: Event;

  @Output()
  hideIncomp: EventEmitter<any> = new EventEmitter();

  incompSubscription: Subscription;

  categoriesUtil = new CategoryUtil();

  evtLeftName: string;
  evtRightName: string;

  espace = ` `;

  constructor(private controlService: ControllerService) { }

  ngOnInit() {
    if (this.evtLeft) { this.evtLeftName = `${this.evtLeft.nom.charAt(0).toUpperCase()}${this.evtLeft.nom.toLowerCase().slice(1)}`; }
    if (this.evtRight) { this.evtRightName = `${this.evtRight.nom.charAt(0).toUpperCase()}${this.evtRight.nom.toLowerCase().slice(1)}`; }
  }

  hideIncompDetail() {
    this.hideIncomp.emit();
    this.controlService.emitEvtDetailState(false);
  }

  getCategoryBGColor(uid: string) {
    return uid === this.detailIncomp.uidLeft ? this.categoriesUtil.getCategoryBDColor(this.evtLeft.categorie) :
    this.categoriesUtil.getCategoryBDColor(this.evtRight.categorie);
  }

  getCategoryIcon(uid: string) {
    return uid === this.detailIncomp.uidLeft ? this.categoriesUtil.getCategoryIconUrl(this.evtLeft.categorie) :
    this.categoriesUtil.getCategoryIconUrl(this.evtRight.categorie);
  }

  saveFilterState() {
    this.hideIncomp.emit();
    this.controlService.userChoice.from = 'incomp';
  }

}
