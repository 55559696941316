import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SigService {
  constructor(private http: HttpClient) {}

  getSigFromUrl(url: string, type: string) {
    let params = new HttpParams();
    params = params.append('type', type);
    return this.http.get(url, {params});
  }
}
