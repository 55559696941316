/* ************************* Le timer permet d'interroger le serveur chaque 10 minutes********
* en minute;
* NEW_FACTS: Envoyer la requête chaque 'NEW_FACTS' minutes pour ramener les nouvaux faits qui viennent d'être inséré dans la BDD;
*/

export const REQUETE_TIMER = {
  NEW_FACTS : 30,
  FREQUENCE : 10,
};


/* ************************* Les deux timers mise à jour plus rapidement, chaque 0.5 minutes ********
* en minuit;
* FACT_FREQUENCE: Vérifier les nouveaux faits éligibles chaque 'FACT_FREQUENCE' minuit; //TODO, à supprmier
* EVTS_FREQUENCE: Vérifier les nouveaux données reçus éligibles chaque 'EVTS_FREQUENCE' minuit;  //TODO, à supprmier
* FREQUENCE: Vérifier les nouveaux données reçus éligibles chaque 'FREQUENCE' minuit;
* SUIVI_FREQUENCE: Vérifier les nouveaux données reçus éligibles chaque 'SUIVI_FREQUENCE' minuit pour la mode 'suivi';
*/
export const UPDATING_TIMER = {
  FACT_FREQUENCE : 0.5,
  EVTS_FREQUENCE : 0.5,
  FREQUENCE : 0.5,
  SUIVI_FREQUENCE : 0.5,
};


/* ************************* MSG pour l'arrivées du nouveaux faits ********
* en minute;
* DURATION: Envoyer la requête chaque 'DURATION' minutes;
*/
export const NEW_FACT_MSG = {
  DURATION : 1,
};

