import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent {
  dateUpdated: Date = new Date();
  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: any) {
    // console.log("input:"+data.date);
    this.dateUpdated = new Date(data.date);
  }
}
