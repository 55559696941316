export class HeatPoint {
  public lat: number;
  public lng: number;
  public value: number;

  constructor(lat: number, lng: number, noteRisk?: number) {
    this.lat = lat;
    this.lng = lng;
    if (noteRisk) {
      this.value = noteRisk;
    } else {
      this.value = null;
    }
  }
}
