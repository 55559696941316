import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css'],
})
export class SplashScreenComponent implements OnInit {
  // Ce component ne fait rien, il existe seulement pour la rédirection vers la page de keycloak.
  // Si l'utilisateur arrive dessus, on vérifie son role et le renvoie sur map

  constructor(private router: Router) {
  }

  ngOnInit() {
    console.log("Version : " + environment.VERSION);
    this.router.navigate(['map']);
  }
}
