import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'myDatePipe'})
export class MyDatePipe implements PipeTransform {
  transform(date: string): string {
    let target = '';
    // let format = { day: 'numeric', month: 'long', year: 'numeric',hour:'numeric',minute:'numeric', second:'numeric'};
    const format = { month: 'long'};
    const month = new Date(date).toLocaleDateString('fr-FR', format);
    const year = date.substring(0, 4);
    const dateLocale = date.substring(8, 10);
    const hour = date.substring(11, 13);
    const minute = date.substring(14, 16);
    const second = date.substring(17, 19);
    target = `${dateLocale} ${month} ${year} ${hour}:${minute}:${second}`;
    return target;
  }
}
