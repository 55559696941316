import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { CategoryUtil } from 'src/app/utils/category.util';
import { BADGE_CONF } from '../../constants/url.const';
import { Event } from '../../models/event.model';
import { ControllerService } from 'src/app/services/controller.service';

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventOverviewComponent implements OnInit, OnChanges {
  private categoryUtil = new CategoryUtil();
  @Input() detailEvent: Event;
  @Output() filterState: EventEmitter<any> = new EventEmitter();

  @Output() listState: EventEmitter<any> = new EventEmitter();
  isShow: boolean;
  provisionalBadgeText: string;
  constructor(private controllerService: ControllerService) {}

  ngOnInit() {
    this.provisionalBadgeText = BADGE_CONF.PROVISIONAL_BADGE.TEXT;
  }

  ngOnChanges() {
    if (
      this.detailEvent &&
      this.detailEvent.adresseNormee !== null &&
      this.detailEvent.adresseNormee !== undefined &&
      this.detailEvent.adresseNormee
    ) {
      this.isShow = this.detailEvent.adresseNormee.trim() !== '' && this.detailEvent.adresseNormee.trim() !== '-';
    }
  }

  getColorOfEvent() {
    return this.categoryUtil.getCategoryBDColor(this.detailEvent.categorie);
  }

  getFontColorOfEvent() {
    return this.categoryUtil.getCategoryFontColor(this.detailEvent.categorie);
  }

  getIcon() {
    return this.categoryUtil.getCategoryIconUrl(this.detailEvent.categorie);
  }

  isEvent() {
    return this.detailEvent.flagType === 'E';
  }

  isProvisionalEvt() {
    return this.detailEvent.statut === 'EVT_A_VALIDER';
  }

  emitShowDetail() {

    this.listState.emit(true);
    this.filterState.emit();
    this.controllerService.emitEvtSrcList(this.detailEvent);
  }
}
