import { Injectable } from '@angular/core';
import { Filter } from '../models/filter.model';
import { Ranger } from '../models/ranger.model';
import { BehaviorSubject} from 'rxjs';
import { DatePair } from '../models/date.pair.model';
import { PlageTemporelle } from '../constants/plage.temporelle';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filter = new Filter();
  filterSubject = new BehaviorSubject<Filter>(new Filter());
  fromtoDate = new DatePair();

  constructor() {}

  emitFilterSubject() {
    // console.log('emit new filter');
    this.filterSubject.next(this.filter);
  }

  getFilterNatures(): string[] {
      return this.filter.categories;
  }

  setFilterCategory(categories) {
    this.filter.categories = categories;
    this.emitFilterSubject();
  }

  getFilterSecteurs(): string[] {
    return this.filter.secteurs;
  }
  setFilterSecteurs(secteurs: string[]) {
    this.filter.secteurs = secteurs;
    this.emitFilterSubject();
  }

  getFilterFromDate(): Date {
    return this.filter.fromDate;
  }

  getFilterToDate(): Date {
    return this.filter.toDate;
  }

  setFilterFromToDate(fromDate: Date, toDate: Date) {
    this.filter.fromDate = fromDate;
    this.filter.toDate = toDate;
    this.emitFilterSubject();
  }

  keepFilterState(dateChosen: Date) {
    const prevFromDate = this.filter.fromDate;
    const prevToDate = this.filter.toDate;
    switch (this.filter.plageTemporelle) {
        case PlageTemporelle.Jour:
            prevFromDate.setFullYear(dateChosen.getFullYear());
            prevFromDate.setMonth(dateChosen.getMonth());
            prevFromDate.setDate(dateChosen.getDate());

            prevToDate.setFullYear(dateChosen.getUTCFullYear());
            prevToDate.setMonth(dateChosen.getUTCMonth());
            prevToDate.setDate(dateChosen.getUTCDate() + 1);
            prevToDate.setHours(23);
            prevToDate.setMinutes(59);
            prevToDate.setSeconds(59);
            // console.log("Jour from:"+prevFromDate.getUTCHours()+",to:"+prevToDate.getHours())
            break;

        case PlageTemporelle.Matin:
            prevFromDate.setFullYear(dateChosen.getUTCFullYear());
            prevFromDate.setMonth(dateChosen.getUTCMonth());
            prevFromDate.setDate(dateChosen.getUTCDate());
            prevFromDate.setHours(6);

            prevToDate.setFullYear(dateChosen.getUTCFullYear());
            prevToDate.setMonth(dateChosen.getUTCMonth());
            prevToDate.setDate(dateChosen.getUTCDate());
            prevToDate.setHours(13);

            // console.log("Matin from:"+prevFromDate.getUTCDate()+",to:"+prevToDate.getUTCDate())
            break;

        case PlageTemporelle.Aprem:
            prevFromDate.setFullYear(dateChosen.getUTCFullYear());
            prevFromDate.setMonth(dateChosen.getUTCMonth());
            prevFromDate.setDate(dateChosen.getUTCDate());
            prevFromDate.setHours(13);

            prevToDate.setFullYear(dateChosen.getUTCFullYear());
            prevToDate.setMonth(dateChosen.getUTCMonth());
            prevToDate.setDate(dateChosen.getUTCDate());
            prevToDate.setHours(20);

            // console.log("Aprem from:"+prevFromDate.getUTCDate()+",to:"+prevToDate.getUTCDate())
            break;
        case PlageTemporelle.Nuit:
            prevFromDate.setFullYear(dateChosen.getUTCFullYear());
            prevFromDate.setMonth(dateChosen.getUTCMonth());
            prevFromDate.setDate(dateChosen.getUTCDate());
            prevFromDate.setHours(20);

            prevToDate.setFullYear(dateChosen.getUTCFullYear());
            prevToDate.setMonth(dateChosen.getUTCMonth());
            prevToDate.setDate(dateChosen.getUTCDate() + 1);
            prevToDate.setHours(6);

            // console.log("Nuit from:"+prevFromDate.getUTCDate()+",to:"+prevToDate.getUTCDate())
            break;

        case PlageTemporelle.Ponctuel:
            prevFromDate.setFullYear(dateChosen.getUTCFullYear());
            prevFromDate.setMonth(dateChosen.getUTCMonth());
            prevFromDate.setDate(dateChosen.getUTCDate());

            prevToDate.setFullYear(dateChosen.getUTCFullYear());
            prevToDate.setMonth(dateChosen.getUTCMonth());
            prevToDate.setDate(dateChosen.getUTCDate());

            // console.log("Pontuel from:"+prevFromDate.getHours()+",to:"+prevToDate.getHours())
            break;

        default:
          // console.log("problème de changement de la date");
          break;
      }
    this.setFilterFromToDate(prevFromDate, prevToDate);
  }

  getFilterPlageTemporelle(): string {
      return this.filter.plageTemporelle;
  }

  setFilterPlageTemporelle(temporelle: string) {
    this.filter.plageTemporelle = temporelle;
  }

  getFilterNoteRisque() {
    return this.filter.noteRisque;
  }

  setFilterNoteRisque(noteRisk: Ranger) {
    this.filter.noteRisque = noteRisk;
    this.emitFilterSubject();
  }

  getFilterIncompChecked() {
    return this.filter.incompChecked;
  }

  setFilterIncomp(incompChecked: boolean) {
    this.filter.incompChecked = incompChecked;
    this.emitFilterSubject();
  }

  getFilterProvisoireChecked() {
    return this.filter.provisoireChecked;
  }

  setFilterProvisoireChecked(provChecked: boolean) {
    this.filter.incompChecked = provChecked;
    this.emitFilterSubject();
  }

  setFilterProvisoire(provisoireChecked: boolean) {
    this.filter.provisoireChecked = provisoireChecked;
    this.emitFilterSubject();
  }
}
