import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { Indicateur } from '../models/indicateur.model';
import { Indice } from '../models/indice.model';
import { ResumeIndicateur } from '../models/resume.indicateurs.model';
import { CategoryUtil } from '../utils/category.util';
import { AppConfigService } from './app.config.service';
import {takeUntil} from 'rxjs/operators';

interface IndiceUser {
  code?: string;
  nom: string;
  dateIndicateur: Date;
  secteur: string;
  valeur: number;
  commentaire?: string;
  auteur?: string;
  dateSuppression?: Date;
}
const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class IndicateurService implements OnInit {
  date: Date;
  categoryUtil = new CategoryUtil();

  indice = new Indice();
  indiceSubject = new BehaviorSubject<Indice>(this.indice);

  private urlApiIndicateur = '';
  private urlApiEvenements = '';
  public apiCallUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
    this.urlApiIndicateur = this.appConfigService.getAppConfig().base_api_url + this.appConfigService.getAppConfig().api_url_indicateurs;
    this.urlApiEvenements = this.appConfigService.getAppConfig().base_api_url + this.appConfigService.getAppConfig().api_url_evenements;
  }

  ngOnInit() { }

  loadIndice(date: Date) {
    if (!date) { date = new Date(); }
    const urlSecteur =
    this.urlApiIndicateur + '/note?date=' + this.convertDateToStr(date) + '&nom=global&secteur=Tous';
    this.http.get<any>(urlSecteur)
      .pipe(takeUntil(this.apiCallUnsubscribe$))
      .subscribe(
        res => {
          this.indice.value = res.indice,
            this.indice.dateIndice = date,
            this.indice.name = res.nom,
            this.indiceSubject.next(this.indice);
        });
  }

  getResumeIndicateurList(dateParam?: Date) {
    const urlIndicateurList =
    this.urlApiIndicateur +
      '/resume?date=' +
      this.convertDateToStr(dateParam) +
      '&secteurs=Tous&moins=1&plus=30';
    return this.http.get<ResumeIndicateur[]>(urlIndicateurList);
  }

  getIndicateurList() {
    const urlIndicateurUrl =
    this.urlApiIndicateur +
      '/list?date=' +
      this.convertDateToStr(this.date) +
      '&secteurs=Tous&moins=0&plus=0';
    return this.http.get<Indicateur[]>(urlIndicateurUrl);
  }

  getSecteur(dateParam: Date) {
    const urlSecteur =
    this.urlApiIndicateur +
      '/list?date=' +
      this.convertDateToStr(dateParam) +
      '&secteurs=Centre,Sud,Nord,Littoral&moins=0&plus=0';
    return this.http.get<Indicateur[]>(urlSecteur);
  }

  getRepartitions() {
    const urlRepartitions =
    this.urlApiEvenements +
      '/repartitions?date=' +
      this.convertDateToStr(this.date) +
      '&natures=' +
      this.categoryUtil.getAllCategoryForServerSansTravaux();
    return this.http.get<Map<string, any[]>>(urlRepartitions);
  }

  insertUserIndice(indiceUser: IndiceUser) {
      return this.http
        .post<any>(
          this.appConfigService.getAppConfig().base_api_url + this.appConfigService.getAppConfig().api_url_indice_user,
          indiceUser,
        );
  }

  getRepartitionsByCodePostal(secteur: string) {
    const urlRepartitions =
    this.urlApiEvenements +
      '/repartitionsByCodePostal?date=' +
      this.convertDateToStr(this.date) +
      '&secteur=' +
      secteur +
      '&categories=' +
      this.categoryUtil.getAllCategoryForServerSansTravaux();
    return this.http.get<Map<string, any[]>>(urlRepartitions);
  }

  setDate(date: Date): void {
    this.date = date;
  }

  convertDateToStr(date: Date): string {
    const dateSelected: Date = new Date(date);
    const month = dateSelected.getMonth() + 1 < 10 ? '0' + (dateSelected.getMonth() + 1) + '' : dateSelected.getMonth() + 1;
    const jour = dateSelected.getDate() < 10 ? '0' + dateSelected.getDate() + '' : dateSelected.getDate() + '';
    return dateSelected.getFullYear().toString() + '-' + month + '-' + jour;
  }

  cancelApiRequest() {
    this.apiCallUnsubscribe$.next();
    this.apiCallUnsubscribe$.complete();
  }
}
